@if (spinner) {<app-spinner></app-spinner>}
<div id="container-page">
  <div class="upper-section mb-5">
    <div class="banner">
      <div class="banner-image">
        <img src="../../../../assets/LP/MOBILE/BANNER_Teste.jpeg" alt="Revenda Rommanel">
      </div>
    </div>
    <div class="cadastro">
      <ng-container *ngIf="step1Form">
        <form [formGroup]="step1Form" (ngSubmit)="onSubmit()">
          <h2 class="smaller-title-text mb-3">
            <b>CADASTRE-SE AGORA</b><br>
            <span style="font-size: calc(100%-4px);">DÊ O PRIMEIRO PASSO</span>
          </h2>
          <div class="form-group">
            <div class="input--container">
              <input type="text" class="form-control" id="nome" placeholder="Nome" formControlName="nome">
              <small class="text-danger" *ngIf="displayErrorMessage('nome')">
                {{ displayErrorMessage('nome') }}
              </small>
            </div>
            <div class="input--container input--date">
              <input (focus)="triggerHidden()" type="text" class="form-control" formControlName="dataNascimento" mask="00/00/0000"
              placeholder="dd/mm/yyyy">
              <span class="placeholder-date" [ngClass]="{'hidden': hideDataNascimento}">Data de nascimento</span>
              <small class="text-danger" *ngIf="displayErrorMessage('dataNascimento')">
                {{ displayErrorMessage('dataNascimento') }}
              </small>
            </div>
            <div class="input--container">
              <input type="text" class="form-control" placeholder="CPF" formControlName="cpf" mask="000.000.000-00">
              <small class="text-danger" *ngIf="displayErrorMessage('cpf')">
                {{ displayErrorMessage('cpf') }}
              </small>
            </div>
            <div class="input--container">
              <input type="email" class="form-control" placeholder="E-mail" formControlName="email">
              <small *ngIf="displayErrorMessage('email')" class="text-danger">{{ displayErrorMessage('email') }}</small>
            </div>
            <div class="input--container">
              <input type="text" class="form-control" placeholder="Cel" formControlName="celular" mask="(00) 0 0000-0000">
              <small *ngIf="displayErrorMessage('celular')" class="text-danger">{{ displayErrorMessage('celular')
                }}</small>
            </div>
            <div class="input--container">
              <input type="text" class="form-control" placeholder="Cep" formControlName="cep" mask="00000-000" #cepEl>
              <small *ngIf="displayErrorMessage('cep')" class="text-danger">{{ displayErrorMessage('cep') }}</small>
            </div>
            <div class="input--container--password">
              <input type="password" class="form-control" id="password" placeholder="Senha" formControlName="senha">
              <span (click)="showHidePass('password', 'iconPass')" class="password-toggle">
                <i [ngClass]="'fa-eye-slash'" id="iconPass" class="fa"></i>
              </span>
              <small class="text-danger" *ngIf="displayErrorMessage('senha')">
                {{ displayErrorMessage('senha') }}
              </small>
            </div>
            <!-- <div class="input--container--password">
          <input type="password" class="form-control" id="confirmPassword" placeholder="Confirmação de senha" formControlName="confirmaSenha">
          <span (click)="showHidePass('confirmPassword', 'iconConfirmPass')" class="password-toggle">
            <i [ngClass]="'fa-eye-slash'" id="iconConfirmPass" class="fa"></i>
          </span>
          <small class="text-danger" *ngIf="displayErrorMessage('confirmaSenha')">
            {{ displayErrorMessage('confirmaSenha') }}
          </small>
        </div> -->
          </div>
          <div class="submit-container">
            <div class="aceite-container">
              <input type="checkbox" name="termos-de-uso" formControlName="aceiteTermos"
                title="Declaro ter lido e aceito os termos de uso e aviso de privacidade">
              <label for="termos-de-uso">Declaro ter lido e aceito os
                <a (click)="openModal(termos)" href="javascript:void(0)">
                  <strong>termos de uso</strong>
                </a>
                e o
                <a (click)="openModal(politica)" href="javascript:void(0)">
                  <strong>aviso de privacidade.</strong>
                </a></label>
            </div>
            <div class="aceite-container">
              <input type="checkbox" name="promocoes-e-ofertas" formControlName="aceiteOferta"
                title="Declaro ter lido e aceito os termos de uso e aviso de privacidade">
              <label for="promocoes-e-ofertas">Gostaria de receber promoções e ofertas de produtos financeiros
                (opcional)</label>
            </div>

            <div class="submit-button-container">
              <input class="submit-button" type="submit" name="submit" value="Continuar cadastro"
                [class.valid-form]="step1Form.valid" [class.invalid-form]="!step1Form.valid">
            </div>

          </div>
        </form>
      </ng-container>
    </div>
  </div>


  <!-- VANTAGENS EXCLUSIVAS -->
  <div class="container-fluid mb-5 d-flex justify-content-center  pb-1">
    <div class="container">
      <div class="d-flex flex-column">
        <h1 class="title-text text-center mb-3">
          <b>VANTAGENS</b><br>
          EXCLUSIVAS
        </h1>
      </div>

      <div class="cards-area">

        <!-- Card Lucro -->
        <div [ngClass]="getCardClass(0)" class="card card-40" (click)="toggleExpand(0)">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon">
                  <img src="../../../../assets/LP/MOBILE/02_Vantagens_Exclusivas/02.1_Lucro/Moedas.png" alt="Icone" />
                </div>
                <div class="ms-3">
                  <h5 class="card-title">Lucro</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(0, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[0], 'fa-times': expandedState[0]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[0]" class="card-text mt-2">
              Somos referência nacional no setor de joias banhadas, com uma margem de lucro de <b>46%</b>.
            </div>
          </div>
        </div>

        <!-- Card Credibilidade -->
        <div [ngClass]="getCardClass(1)" class="card  card-40" (click)="toggleExpand(1)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon">
                  <img src="../../../../assets/LP/MOBILE/02_Vantagens_Exclusivas/02.2_Credibilidade/Diamante.png"
                    alt="Icone" />
                </div>
                <div class="ms-3">
                  <h5 class="card-title">Credibilidade</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(1, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[1], 'fa-times': expandedState[1]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[1]" class="card-text mt-2">
              <p>
                Mais de 4 mil modelos de joias <b>hipoalergênicas</b> a níquel, ou seja, que não causam alergia.
              </p>
              <p>
                As nossas joias banhadas possuem <b>a maior camada de Ouro</b> do mercado e
                <b>Prata 925 maciça</b> com banho de prata pura.
              </p>
            </div>
          </div>
        </div>

        <!-- Card Ferramentas -->
        <div [ngClass]="getCardClass(2)" class="card  card-40" (click)="toggleExpand(2)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon">
                  <img src="../../../../assets/LP/MOBILE/02_Vantagens_Exclusivas/02.3_Ferramentas/Celular.png"
                    alt="Icone" />
                </div>
                <div class="ms-3">
                  <h5 class="card-title">Ferramentas</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(2, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[2], 'fa-times': expandedState[2]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[2]" class="card-text mt-2">
              <ul>
                <li><b>Loja online exclusiva:</b> para comprar joias no conforto de sua casa.</li>
                <li><b>App Meu Mundo Rommanel:</b> para a gestão do seu negócio.</li>
                <li><b>Academia Rommanel:</b> plataforma exclusiva com mais de 120 treinamentos.</li>
                <li><b>Materiais de apoio:</b> que complementam o aprendizado acerca do mundo Rommanel.</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Card Reconhecimento -->
        <div [ngClass]="getCardClass(3)" class="card  card-40" (click)="toggleExpand(3)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon">
                  <img src="../../../../assets/LP/MOBILE/02_Vantagens_Exclusivas/02.4_Reconhecimento/Medalha.png"
                    alt="Icone" />
                </div>
                <div class="ms-3">
                  <h5 class="card-title">Reconhecimento</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(3, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[3], 'fa-times': expandedState[3]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[3]" class="card-text mt-2">
              <p>Diversas promoções, eventos e premiações exclusivas ao longo do ano para nossos (as) revendedores (as).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TERCEIRA SECTION -->
  <div class="container-fluid mb-5 d-flex justify-content-center pb-2">
    <div class="container pb-2">
      <div class="d-flex flex-column">
        <h1 class="title-text text-center mb-3">
          <b>PROPOSTAS</b><br>
          ÚNICAS
        </h1>
      </div>

      <swiper-container id="swiper-container1">
        <swiper-slide>
          <div class="swiper carroussel-card d-flex p-1 mb-1">
            <img src="../../../assets/LP/MOBILE/03_Propostas_Únicas/03.1_Propostas Únicas_Ações.png" alt="Ações">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper carroussel-card d-flex p-1 mb-1">
            <img src="../../../assets/LP/MOBILE/03_Propostas_Únicas/03.2_Propostas Únicas_Disney.png" alt="Disney">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper carroussel-card d-flex p-1 mb-1">
            <img src="../../../assets/LP/MOBILE/03_Propostas_Únicas/03.3_Propostas Únicas_Watches.png" alt="Watches">
          </div>
        </swiper-slide>
      </swiper-container>

      <div class="propostas-unicas-desktop">
        <div class="propostas-card p-1 mb-1">
          <img src="../../../assets/LP/MOBILE/03_Propostas_Únicas/03.1_Propostas Únicas_Ações.png" alt="Ações">
        </div>
        <div class="propostas-card p-1 mb-1">
          <img src="../../../assets/LP/MOBILE/03_Propostas_Únicas/03.2_Propostas Únicas_Disney.png" alt="Disney">
        </div>
        <div class="propostas-card p-1 mb-1">
          <img src="../../../assets/LP/MOBILE/03_Propostas_Únicas/03.3_Propostas Únicas_Watches.png" alt="Watches">
        </div>
      </div>
    </div>
  </div>

  <!-- QUARTA SECTION -->
  <div class="container-fluid mb-5 d-flex justify-content-center">
    <div class="container">
      <div class="d-flex flex-column">
        <h1 class="title-text text-center mb-3">
          <b>CONHEÇA A MAGIA</b><br>
          DA BORBOLETA
        </h1>
      </div>

      <div class="cards-area">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/yIfS8BpbZsc?si=NzwK93VLC28sYBq-" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
          </iframe>
        </div>
        <a href="pre-cadastro/{{utmsParams}}" class="button-transformar">
          <h1>Quero transformar minha vida</h1>
        </a>
      </div>
    </div>
  </div>

  <!-- QUINTA SECTION -->
  <div class="container-fluid mb-5 d-flex justify-content-center">
    <div class="container">
      <div class="d-flex flex-column">
        <h1 class="title-text text-center mb-3">
          COMO SE TORNAR<br>
          <b>REVENDEDOR(A) ROMMANEL</b>
        </h1>
      </div>

      <div class="d-flex justify-content-around align-items-start mobile">
        <div class="col-md-6 d-flex justify-content-end p-2">
          <img src="../../../assets/LP/MOBILE/05_Como_Revender/Diagrama_Completo.png" alt="Diagrama"
            class="img-section-5" />
        </div>
        <div class="col-md-6 d-flex flex-column align-items-start p-2">
          <div class="step-text mb-5">
            <h5>1. PRÉ-CADASTRO</h5>
            <p>Faça o seu pré-cadastro <a href="/pre-cadastro">clicando aqui.</a></p>
          </div>
          <div class="step-text">
            <h5>2. CONFIRMAÇÃO</h5>
            <p>Você receberá um e-mail de confirmação do cadastro com login e senha para acessar todas as nossas
              plataformas digitais.</p>
          </div>
          <div class="step-text">
            <h5>3. SEU PRIMEIRO PEDIDO</h5>
            <p>Aguarde o contato da loja distribuidora escolhida ou faça seu primeiro pedido pela <a>loja online</a>,
              utilizando login e senha enviados no e-mail de confirmação do cadastro. Caso prefira, você também poderá
              entrar em contato via telefone com a loja.</p>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column desktop align-items-center">
        <div class="d-flex justify-content-center">
          <img src="../../../assets/LP/DESKTOP/05_Como_Revender/Diagrama_Completo.png" alt="Diagrama"
            class="img-section-5" />
        </div>
        <div class="steps">
          <div class="step-text me-5">
            <h5>1. PRÉ-CADASTRO</h5>
            <p>Faça o seu pré-cadastro <a href="/pre-cadastro">clicando aqui.</a></p>
          </div>
          <div class="step-text me-5">
            <h5>2. CONFIRMAÇÃO</h5>
            <p>Você receberá um e-mail de confirmação do cadastro com login e senha para acessar todas as nossas
              plataformas digitais.</p>
          </div>
          <div class="step-text">
            <h5>3. SEU PRIMEIRO PEDIDO</h5>
            <p>Aguarde o contato da loja distribuidora escolhida ou faça seu primeiro pedido pela <a
                href="https://loja.rommanel.com.br/" target="_blank">loja online</a>, utilizando login e senha enviados
              no e-mail de confirmação do cadastro. Caso prefira, você também poderá entrar em contato via telefone com
              a loja.</p>
          </div>
        </div>
      </div>

      <!-- <div class="d-flex justify-content-center">
        <a href="https://www.rommanel.com.br/Institucional/encontre-uma-loja" target="_blank" class="button-transformar">
          <h1>Localizar Loja Distribuidora</h1>
        </a>
      </div>  -->
    </div>
  </div>

  <!-- SEXTA SECTION -->
  <div class="container-fluid d-flex justify-content-center">
    <div class=" container">
      <div class="d-flex flex-column">
        <h1 class="title-text text-center mb-2">
          TRANSFORMAÇÃO<br>
          <b>ROMMANEL</b>
        </h1>
      </div>

      <swiper-container id="swiper-container2">
        <swiper-slide>
          <div class="swiper carroussel-card d-flex flex-column p-1 mb-2">
            <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.1_Odineide.png" alt="Depoimento Odineide">
            <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.1_Roberto.png" alt="Depoimento Roberto">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper carroussel-card d-flex flex-column p-1 mb-2">
            <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.2_Ana.png" alt="Depoimento Ana">
            <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.2_Ivana.png" alt="Depoimento Ivana">
          </div>
        </swiper-slide>
      </swiper-container>

      <div class="transformacao-cards desktop">
        <div class="d-flex p-1 mb-2 transformacao-card">
          <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.1_Odineide.png" alt="Depoimento Odineide">
        </div>
        <div class="d-flex p-1 mb-2 transformacao-card-2">
          <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.1_Roberto.png" alt="Depoimento Roberto">
        </div>
        <div class="d-flex p-1 mb-2 transformacao-card">
          <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.2_Ana.png" alt="Depoimento Ana">
        </div>
        <div class="d-flex p-1 mb-2 transformacao-card-2">
          <img src="../../../assets/LP/MOBILE/06_Depoimentos/06.2_Ivana.png" alt="Depoimento Ivana">
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mb-5">
    <a href="/pre-cadastro" class="button-transformar">
      <h1>Realize Seus Sonhos</h1>
    </a>
  </div>

  <!-- SÉTIMA SECTION -->
  <div class="container-fluid mb-5 d-flex justify-content-center">
    <div class="container">
      <div class="d-flex flex-column">
        <h1 class="title-text text-center mb-3">
          <b>DÚVIDAS</b><br>
          FREQUENTES
        </h1>
      </div>

      <div class="row-cols-1 row-cols-md-5 g-3 cards-area">

        <!-- Card 1 -->
        <div [ngClass]="getCardClass(4)" class="card" (click)="toggleExpand(4)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Quais são os requisitos para revender Rommanel?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(4, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[4], 'fa-times': expandedState[4]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[4]" class="card-text mt-2">
              Para se tornar um(a) revendedor(a), basta ter <b>mais de 18 anos</b>, fazer o pré-cadastro e realizar a
              <b>primeira compra</b>, que pode ser feita em qualquer loja exclusiva Rommanel ou em nossa loja online
              oficial:<br><br>

              <a>www.loja.rommanel.com.br</a>
            </div>
          </div>
        </div>

        <!-- Card 2 -->
        <div [ngClass]="getCardClass(5)" class="card" (click)="toggleExpand(5)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Além do cadastro, o que eu preciso para revender Rommanel?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(5, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[5], 'fa-times': expandedState[5]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[5]" class="card-text mt-2">
              Depois de se cadastrar, você deve realizar uma <b>compra inicial</b> com, no mínimo, <b>15 peças</b>.
            </div>
          </div>
        </div>

        <!-- Card 3 -->
        <div [ngClass]="getCardClass(6)" class="card" (click)="toggleExpand(6)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">A Rommanel é consignada?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(6, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[6], 'fa-times': expandedState[6]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[6]" class="card-text mt-2">
              Não, a <b>Rommanel não é consignada</b>. Para ativar seu cadastro, é preciso realizar uma <b>primeira
                compra</b>. Além disso, contamos com uma equipe preparada para te ajudar a escolher as melhores opções
              dentro da sua expectativa de investimento.
            </div>
          </div>
        </div>

        <!-- Card 4 -->
        <div [ngClass]="getCardClass(7)" class="card" (click)="toggleExpand(7)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Qual a margem de lucro?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(7, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[7], 'fa-times': expandedState[7]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[7]" class="card-text mt-2">
              Nós possuímos a maior margem de lucro do mercado no setor de joias banhadas, que é <b>46%</b>.
            </div>
          </div>
        </div>

        <!-- Card 5 -->
        <div [ngClass]="getCardClass(8)" class="card" (click)="toggleExpand(8)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Como posso adquirir as joias para revenda?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(8, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[8], 'fa-times': expandedState[8]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[8]" class="card-text mt-2">
              <p><b>1.</b> Depois de você já ter se cadastrado, basta acessar o site <a>www.loja.rommanel.com.br</a> e
                clicar em <b>“Continuar acesso”</b>.</p>
              <p><b>2.</b> Digite seu e-mail de cadastro e a senha de acesso que recebeu por e-mail, e pronto!</p><br>
              <p>Caso não lembre a senha, clique na opção “Esqueci a minha senha” para que seja enviada uma nova senha
                ao seu e-mail de cadastro.</p>
            </div>
          </div>
        </div>

        <!-- Card 6 -->
        <div [ngClass]="getCardClass(9)" class="card" (click)="toggleExpand(9)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Posso comprar no site e retirar na loja?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(9, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[9], 'fa-times': expandedState[9]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[9]" class="card-text mt-2">
              <p>Sim! Você pode comprar no site e escolher uma das lojas com ponto de retirada para buscar os seus
                produtos, tudo isso sem pagar frete. Veja como funciona:</p><br>
              <p><b>1.</b> Escolha sua(s) peça(s) adicionando na Sacola de Compras;</p>
              <p><b>2.</b> Selecione o tipo de entrega como “Retira na Loja” e finalize o pedido.</p><br>
              <p>Pronto! Agora é só aguardar o e-mail informando que seu pedido está pronto e retirar na loja escolhida.
                O horário de retirada de pedidos será de acordo com o horário de funcionamento da loja.</p><br>
              <p>*A opção de retirar na loja ainda não está disponível para todas as unidades, mas estamos trabalhando
                para expandir ainda mais o atendimento a revendedores.</p>
            </div>
          </div>
        </div>

        <!-- Card 7 -->
        <div [ngClass]="getCardClass(10)" class="card" (click)="toggleExpand(10)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Como fazer a gestão das minhas vendas?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(10, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[10], 'fa-times': expandedState[10]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[10]" class="card-text mt-2">
              Você pode fazer a gestão das suas vendas através das ferramentas de gerenciamento do <a
                href="https://www.rommanel.com.br/meu-mundo-rommanel/" target="_blank">App Meu Mundo Rommanel</a>, o
              aplicativo <b>gratuito</b> e com <b>benefícios exclusivos</b> para as consultoras e consultores Rommanel.
            </div>
          </div>
        </div>

        <!-- Card 8 -->
        <div [ngClass]="getCardClass(11)" class="card" (click)="toggleExpand(11)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Eu preciso fazer algum treinamento?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(11, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[11], 'fa-times': expandedState[11]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[11]" class="card-text mt-2">
              Nossos treinamentos são feitos por meio da nossa plataforma de capacitação, a <a>Academia
                Rommanel</a>.<br><br>
              O acesso para revendedores e revendedoras é totalmente gratuito, e você recebe seu login e senha de acesso
              por e-mail, logo após a aprovação de seu cadastro.
            </div>
          </div>
        </div>

        <!-- Card 9 -->
        <div [ngClass]="getCardClass(12)" class="card" (click)="toggleExpand(12)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Meu cadastro está inativo. Como reativar?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(12, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[12], 'fa-times': expandedState[12]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[12]" class="card-text mt-2">
              Para a reativação do cadastro, é preciso <a>realizar uma compra</a> de, no mínimo, <b>10 peças</b>.
            </div>
          </div>
        </div>

        <!-- Card 10 -->
        <div [ngClass]="getCardClass(13)" class="card" (click)="toggleExpand(13)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Como manter minha conta ativa?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(13, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[13], 'fa-times': expandedState[13]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[13]" class="card-text mt-2">
              Para manter o cadastro ativo, é preciso realizar uma <b>compra mínima</b> durante o <b>prazo de 90
                dias</b>.
            </div>
          </div>
        </div>

        <!-- Card 11 -->
        <div [ngClass]="getCardClass(14)" class="card" (click)="toggleExpand(14)">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <h5 class="card-title">Quais os canais de atendimento da Rommanel, caso eu precise de ajuda?</h5>
                </div>
              </div>
              <button class="btn btn-icon" (click)="toggleExpand(14, $event)">
                <i class="fas" [ngClass]="{'fa-plus': !expandedState[14], 'fa-times': expandedState[14]}"></i>
              </button>
            </div>
            <div *ngIf="expandedState[14]" class="card-text mt-2">
              <b>Voz e WhatsApp</b><br>
              SAC Rommanel: <a>0800 011 4990</a><br>
              WhatsApp Rommanel: <a>(11) 97148-3889</a> (somente mensagens de texto)<br><br>

              <b>E-mail</b><br>
              - Loja online, App Meu Mundo Rommanel, Dúvidas e Elogios, Informações sobre revenda:
              <a>atendimento&#64;rommanel.com.br</a><br>
              - Reclamações sobre troca, defeito, entre outros: <a>relacionamento&#64;rommanel.com.br</a><br>
              - Dúvidas sobre a Academia Rommanel: <a>academia&#64;rommanel.com.br</a><br>
              - Para comprar ou revender no exterior: <a>miami&#64;rommanel.com.br</a><br><br>

              Caso queira entrar em contato com uma de nossas lojas, você pode encontrar os telefones e WhatsApp,
              através da aba <b>“Fale com a gente”</b> em nosso site.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #termos let-c="close" let-d="dismiss">
    <section class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="d('')">
        <span aria-hidden="true">&times;</span>
      </button>
    </section>

    <article class="modal-body">
      <h2>Termos de Uso</h2>
      <br />

      <h6>Versão atualizada [15/10/2021]</h6>

      <p>
        Seja bem-vindo aos Termos de Uso de Sites e Aplicativos Rommanel!
      </p>
      <br />

      <p>
        Estes Termos de Uso ("<strong>Termos</strong>") têm como finalidade
        a apresentação a
        <strong>você, Usuário(a)</strong> (individualmente mencionado como
        "<strong>Você</strong>" ou "<strong>Usuário</strong>"), das regras
        para utilização de Sites (“<strong>Sites</strong>”) e
        <strong>Aplicativos</strong>
        (“Aplicativos”) Rommanel.
      </p>

      <p>Você poderá acessar:</p>

      <ul>
        <li>
          <strong>Site Rommanel: </strong> Vitrine Virtual (disponível
          também para o público) e contato para interessados em se tornarem
          um Consultor(a) independente Rommanel (Meu Mundo Rommanel).
        </li>
        <li>
          <strong>E-commerce: </strong> Para adquirir seus Produtos Rommanel
        </li>
        <li>
          <strong>Aplicativo Meu Mundo Rommanel: </strong> criado
          especialmente para auxiliar na gestão do seu negócio.
        </li>
        <li>
          <strong>Academia Rommanel: </strong> conteúdo educacional,
          destinado a aperfeiçoar sua atuação como Consultores(as)
          independente Rommanel.
        </li>
      </ul>

      <p>
        Os Sites e Aplicativos Rommanel são desenvolvidos e providos por MMA
        INDUSTRIA E COMERCIO DE BIJUTERIAS LTDA, inscrita no CNPJ sob o nº
        04.942.899/0001-12, com sede à Rua Santa Monica, nº 1733, Capuava,
        CEP 06.715-865, no município de Cotia/SP
        "<strong>Rommanel</strong>").
      </p>
      <br />

      <section>
        <h4>&nbsp; 1. &nbsp;&nbsp; Aceitação aos Termos</h4>
        <p>
          Ao acessar e usar os Sites e Aplicativos Rommanel Você concorda
          com os Termos, que regulam o relacionamento entre Você e a
          Rommanel. Se Você não concorda com os Termos, Você não deve
          acessar ou usar os Serviços. Mediante referido acesso e uso, os
          presentes Termos imediatamente encerram, substituem e superam
          todos os acordos, Termos e acertos anteriores entre Você e a
          Rommanel.
        </p>
        <p>
          Você será notificado quando os Termos forem alterados, e caso não
          concorde com eles, poderá solicitar o cancelamento da sua conta,
          pelos canais de atendimento mencionados nos presente Termo. O fato
          de você continuar a acessar ou usar os Serviços após essa postagem
          e notificação representa sua aceitação em vincular-se aos Termos
          alterados.
        </p>
        <p>
          A coleta e o tratamento de dados pessoais associados aos Serviços
          estão disciplinados no Aviso de Privacidade, disponibilizado para
          o Usuário no sítio eletrônico:
          <a href="https://www.rommanel.com.br/Institucional/aviso-de-privacidade"
            target="_blank">https://www.rommanel.com.br/Institucional/aviso-de-privacidade.</a>
        </p>
        <br />
      </section>

      <section>
        <h4>&nbsp; 2. &nbsp;&nbsp; Site Rommanel</h4>

        <p>
          Por meio do Site Rommanel, o Você terá acesso a informações
          relevantes sobre assuntos ligados à Rommanel, bem como acerca de
          seus produtos e coleções.
        </p>

        <p>
          <strong>Vitrine Virtual.</strong> Este Site apresenta uma vitrine
          virtual dos produtos da Rommanel, de modo a apresentá-los conforme
          suas categorias e coleções. Contudo,
          <strong>
            <ins>
              Você não poderá fazer a aquisição de qualquer produto através
              desse Site.
            </ins>
          </strong>
        </p>

        <p>
          Os produtos aqui expostos são vendidos em lojas físicas apenas por
          Distribuidores Exclusivos indicados neste site, conforme a região
          do Usuário. Todos os produtos exibidos neste Site podem estar
          disponíveis nestes Distribuidores Exclusivos Rommanel, de acordo
          com sua disponibilidade de estoque.
        </p>

        <p>
          Os produtos Rommanel são vendidos exclusivamente nos
          Distribuidores Exclusivos Rommanel em lojas licenciadas ou por
          meio do e-commerce oficial da Rommanel. A Rommanel não garante a
          autenticidade de produtos vendidos em outros meios online.
        </p>

        <p>
          Caso seja de seu interesse adquirir produtos Rommanel, entre em
          contato com nossos Distribuidores Exclusivos por meio do site
          <a href="https://www.rommanel.com.br/Institucional/encontre-uma-loja"
            target="_blank">https://www.rommanel.com.br/Institucional/encontre-uma-loja</a>, e consulte o local mais
          próximo de Você.
        </p>

        <p>
          A Rommanel, ainda, pode retirar a qualquer tempo seus produtos da
          Vitrine Virtual, bem como alterar quaisquer produtos
          disponibilizados no Site, de acordo com sua disponibilidade e
          sazonalidade.
        </p>

        <p>
          <strong>Preços.</strong> Os preços apresentados no Site são
          valores sugeridos, que podem sofrer variações, de acordo com
          custos de transportes, diferenças tributárias regionais e/ou
          condições de lançamento, exclusividade ou promoções sazonais.
          Desta forma,
          <ins>
            a Rommanel <strong>não</strong> garante que o preço aqui
            disposto seja o mesmo das lojas físicas e/ou dos Distribuidores
            Exclusivos.
          </ins>
        </p>

        <p>
          <strong>Tenha seu próprio negócio.</strong> O Site apresenta a
          Você nossa proposta de negócio, intitulada “Tenha seu próprio
          negócio”. Caso deseje se tornar um(a) Consultora independente
          Rommanel, Você poderá clicar nessa opção disponível em nosso Site
          e seguir o pré-cadastro conforme indicado. Caso Você tenha
          dúvidas, entre em contato conosco através do Chat
          <a href="https://rommanel.zendesk.com/hc/pt-br/requests/new"
            target="_blank">https://rommanel.zendesk.com/hc/pt-br/requests/new</a>.
        </p>

        <p>
          <strong> Meu Negócio Rommanel e acesso à Ýrea Exclusiva.</strong>
          O Site possibilita o direcionamento à Ýrea Exclusiva dos
          Revendedores Rommanel. Caso Você tenha interesse em fazer parte do
          nosso negócio, acesse o site
          <a href="https://cadastro.rommanel.com.br/" target="_blank">https://cadastro.rommanel.com.br/</a>.
        </p>

        <p>
          Seus dados de <i>login</i> e senha para acesso à Ýrea Exclusiva
          são personalíssimos e não são passíveis de cessão, venda ou de
          qualquer outra modalidade de transferência de direitos de uso para
          quem quer que seja.
        </p>

        <p>
          <ins>
            Além disso, seus dados de acesso não devem ser compartilhados
            com terceiros, mesmo que de sua confiança, sendo Você o único
            responsável pela utilização feita de seu cadastro, por meio de
            suas credenciais de acesso.
          </ins>
        </p>

        <p>
          A Rommanel poderá, a seu exclusivo critério e conveniência,
          excluir ou suspender a conta de acesso de determinado usuário de
          seu Site, em virtude de suspeita de inexatidão de informações ou
          prática ato ilícito ou em desconformidade com estes Termos e
          demais políticas do Site. Essa faculdade não exime o usuário de
          fornecer sempre os dados verdadeiros e praticar condutas em
          harmonia com o propósito de existência do Site.
        </p>

        <p>
          <strong> Newsletter:</strong> Você, também, poderá cadastrar o seu
          e-mail em nossa Newsletter. Trata-se do nosso canal para
          divulgação de produtos, promoções e notícias a respeito da
          Rommanel.
        </p>

        <p>
          Ao inserir o seu e-mail no campo indicado, Você concorda com o
          recebimento de e-mails da Rommanel, das empresas do mesmo grupo
          econômico e dos Distribuidores Exclusivos.
        </p>

        <p>
          Será de sua responsabilidade deixar os sistemas de AntiSpam
          configurados em seu e-mail, para recebimento da newsletter.
        </p>

        <p>
          Caso Você tenha interesse em receber nosso
          <i>mailing</i> publicitário, cadastre seu e-mail no campo
          indicado.
        </p>

        <p>
          Caso Você tenha interesse em parar de receber nosso
          <i>mailing</i>
          publicitário, clique no link “descadastrar” no corpo do e-mail
          recebido.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 3. &nbsp;&nbsp; <i>E-commerce</i> Rommanel</h4>

        <p>
          Por meio do <i>E-commerce</i>, o Você terá acesso ao e-commerce
          oficial da Rommanel, para que assim possa efetuar suas compras
          diretamente dos Distribuidores Exclusivos Rommanel, de forma fácil
          e simplificada! O acesso ao E-commerce é realizado no site
          <a href="https://loja.rommanel.com.br/" target="_blank">
            https://loja.rommanel.com.br/.
          </a>
        </p>

        <p>
          <strong>Cadastro.</strong> O registro para obtenção de uma conta
          exige o fornecimento de determinados dados pessoais à Rommanel,
          tais como o nome, data de aniversário, CPF, endereço, telefone e
          e-mail.
        </p>

        <p>
          Você concorda em manter as informações referentes a sua conta,
          completas e atualizadas, caso contrário Você poderá ficar
          impossibilitado de acessar e usar os Serviços. Você também é
          responsável pela segurança e confidencialidade de seu login e
          senha. Salvo autorização por escrito da Rommanel, Você somente
          pode ter uma única conta associada ao seu CPF.
        </p>

        <p>
          A Rommanel se reserva o direito de utilizar todos os meios válidos
          e possíveis para identificar o Usuário, bem como de solicitar
          dados adicionais e documentos que estime serem pertinentes a fim
          de conferir os dados pessoais informados, com o intuito de
          confirmar a identidade do Usuário e garantir maior segurança às
          transações realizadas por meio do
          <i>E-commerce</i>.
        </p>

        <p>
          <strong>Pagamento das compras efetuadas.</strong> Você se
          compromete a realizar o pagamento a partir do momento em que
          concluir o pedido, nos termos e nas condições selecionados quando
          da sua efetivação. Ao preço que conste no carrinho, para cada um
          dos produtos e serviços oferecidos serão somadas as eventuais
          tarifas correspondentes aos gastos de envio (frete) pertinentes.
          Em qualquer caso, tais tarifas serão comunicadas previamente ao
          Você, antes da finalização da compra.
        </p>

        <p>
          O valor do frete para as compras efetuadas varia conforme a
          localidade, peso e dimensão do produto. Para calcular o valor de
          frete a ser pago, bem como prazo de entrega estimado para sua
          região, basta escolher o produto e digitar o CEP no local
          indicado.
        </p>

        <p>
          Você deverá efetuar o pagamento do pedido realizado por meio do
          boleto bancário gerado no momento de finalização da compra, dentro
          do prazo de vencimento deste, indicado no documento, sendo que, o
          não pagamento deste dentro do prazo de pagamento poderá acarretar
          no cancelamento da compra realizada.
        </p>

        <p>
          Atenção! As ofertas são válidas até o término de nossos estoques.
          Ainda, as vendas estão sujeitas à análise e confirmação de dados.
        </p>

        <p>
          O prazo máximo de entrega será informado no ato da compra, sendo
          que sua contagem, em regra, terá início somente após a aprovação
          de pagamento.
        </p>

        <p>
          <strong>Entrega.</strong> A Rommanel compromete-se a aplicar todas
          as medidas necessárias e razoáveis para entregar o produto em
          perfeito estado e na localidade que Você indicar no ato da compra,
          sendo que as informações cadastradas são de sua responsabilidade,
          que, em todos os casos, deverão necessariamente estar
          compreendidas dentro do Território nacional. Após a confirmação do
          pagamento ou confirmação do pedido, os Distribuidores Exclusivos
          Rommanel providenciarão a entrega do produto diretamente no
          endereço indicado por Você, no prazo informado no momento da
          finalização da compra.
        </p>

        <p>
          Nos casos em que houver data específica para a disponibilidade do
          produto, devidamente informada no E-commerce, quando o CEP de
          entrega for digitado, serão adicionadas a data de disponibilidade
          e a quantidade de dias úteis para a entrega. Nessa situação, o
          prazo final será diferenciado e composto pela soma da data de
          disponibilidade e a quantidade de dias úteis para entrega.
        </p>

        <p>
          O prazo de entrega informado durante o procedimento de compra do
          produto leva em consideração o estoque, a região, o processo de
          emissão da nota fiscal, o tempo de preparo do produto e sua origem
          (se importado ou nacional). A cada atualização no status de
          entrega do pedido, se Você tiver solicitado, o sistema do
          E-commerce pode enviar, automaticamente, e-mails de alerta para
          Você.
        </p>

        <p>
          As entregas são realizadas de acordo com as determinações de cada
          Distribuidor Exclusivo Rommanel, sendo necessário que haja pessoa
          civilmente capaz e autorizada por Você no local para receber o
          item portando documento de identificação, sendo necessário checar,
          no ato da entrega, se a mercadoria está conforme o solicitado. Se
          o produto estiver em perfeitas condições, deverá ser assinado o
          Comprovante de Recebimento.
        </p>

        <p>
          Você poderá cancelar seu pedido de acordo com procedimento
          viabilizado pelo E-commerce, desde que a Nota Fiscal ainda não
          tenha sido emitida. Após a emissão da Nota Fiscal, se Você não
          mais desejar receber o pedido, deve recusar o produto no ato da
          entrega e entrar em contato com a Rommanel, por meio do SAC, para
          formalizar a solicitação de cancelamento.
        </p>

        <p>
          Não se consideram atrasos no envio aqueles casos em que o pedido
          saiu do Centro de Distribuição dentro do prazo acordado e não foi
          possível finalizar a entrega por causas atribuídas à Você, como,
          por exemplo, em razão da sua ausência ou de pessoa autorizada no
          local de entrega, recusa da entrega por terceiros, ou fenômenos
          naturais ou sociais (greves, lockout, manifestações, paralisações
          etc.) que impeçam a transportadora de chegar ao local de entrega.
          Nessas situações, a Rommanel não poderá ser responsabilizada pelo
          atraso na entrega dos produtos.
        </p>

        <p>
          Nas circunstâncias em que não for possível concluir a entrega por
          culpa do Usuário ou na ocorrência de situações decorrentes de
          fenômenos naturais ou sociais, o produto será devolvido ao Centro
          de Distribuição da Rommanel ou ao Lojista.
        </p>

        <p>
          Considera-se efetuada cada entrega, a partir do momento no qual a
          empresa de transportes coloca o produto à disposição do Você, o
          que se comprova por meio do sistema de controle utilizado pela
          transportadora.
        </p>

        <p>
          Se houver qualquer falha no produto ou no serviço adquirido, Você
          deve, no ato do recebimento, recusar o recebimento e entrar em
          contato imediatamente com o SAC da Rommanel, caso constate alguma
          das situações a seguir: (i) produto avariado no transporte; (ii)
          embalagem violada, acessórios ou itens faltantes;(iii) produto em
          desacordo com o pedido; (iv) falta da nota fiscal; ou (v)
          discrepância entre a descrição da nota fiscal e produto recebido.
        </p>

        <p>
          Após o aceite dos presentes Termos e a realização do Cadastro,
          Você poderá fazer uso de todas as funcionalidades disponibilizadas
          no E-commerce, podendo adquirir por meio on-line os produtos e
          serviços por ele fornecidos.
        </p>

        <p>
          O prazo máximo de entrega será informado no ato da compra, sendo
          que sua contagem, em regra, terá início somente após a aprovação
          de pagamento, que poderá variar de acordo com a forma de pagamento
          escolhida.
        </p>

        <p>
          Caso haja dificuldade de entrega na 1ª tentativa, será realizada
          mais 01 (uma) tentativa, com intervalo de até 02 (dois) dias
          úteis. Se a entrega não for concretizada nesta nova tentativa, o
          produto voltará para nosso estoque. Para qualquer uma das
          situações, Você será notificado por e-mail, informado e constante
          em nossos cadastros.
        </p>

        <p>
          <strong>Devoluções. Produtos com Defeito.</strong> Você deverá
          entrar em contato com o SAC tão logo tenha conhecimento de algum
          defeito nos produtos adquiridos por meio do E-commerce. O
          Distribuidor Exclusivo Rommanel, contudo, isenta-se da obrigação
          de atender às solicitações de troca ou devolução de qualquer
          produto devolvido: (i) sem que o SAC tenha sido previamente
          comunicado, o que também pode ocorrer por solicitação direta nos
          sites na área restrita a usuários cadastrados; (ii) se for
          constatada a ausência de itens/acessórios que o acompanham; (iii)
          se o defeito tiver ocorrido por uso indevido do Usuário; e/ou (iv)
          em casos de reclamações efetuadas fora dos prazos estabelecidos
          nestes Termos.
        </p>

        <p>
          Não sendo nenhuma das situações acima, Você que receber um produto
          com defeito terá o prazo de até 30 (trinta) dias corridos para
          iniciar o procedimento descrito no Código de Defesa do Consumidor.
        </p>

        <p>
          O produto com defeito deverá ser encaminhado, mediante autorização
          prévia, para o endereço indicado pela Rommanel, em sua embalagem
          original, acompanhado dos manuais, acessórios e, fundamentalmente,
          da primeira via da Nota Fiscal de venda. Caso Você não possua este
          documento, não será aceita a solicitação de troca.
        </p>

        <p>
          O produto defeituoso também poderá ser coletado no endereço de
          entrega por meio de parceiros logísticos da Rommanel. Após a
          chegada do produto ao Centro de Distribuição, a Rommanel
          verificará se as condições supracitadas foram atendidas. Em caso
          afirmativo, providenciará a restituição ou troca do produto de
          acordo com a solicitação feita por Você.
        </p>

        <p>
          Esclarecemos ainda que é assegurado ao fornecedor/fabricante sanar
          o vício do produto, por ele fornecido, no prazo máximo de 30 dias,
          conforme previsto no artigo 18, § 1º do Código de Defesa do
          Consumidor. Caso este prazo seja extrapolado, é seu direito a
          substituição do produto; o cancelamento da venda ou ainda o
          abatimento proporcional de seu preço.
        </p>

        <p>
          A devolução dos produtos no prazo e condições estipuladas, garante
          à Você o direito ao reembolso do valor das peças e do frete
          correspondente. Após receber e conferir corretamente a mercadoria
          nos Centros de Distribuição, a Rommanel realizará o reembolso
          diretamente no cartão de crédito utilizado na compra, em conta
          corrente indicada pelo Usuário ou ordem de pagamento, no caso de
          pagamento com boleto bancário.
        </p>

        <p>
          Nas situações aplicáveis, o valor do pedido será devolvido à Você,
          de acordo com a forma de pagamento utilizada, observadas todas as
          condições aplicáveis do presente Termo.
        </p>

        <p>
          Em compras com cartão de crédito, a administradora do cartão será
          notificada e o estorno poderá ocorrer em até 02 (duas) faturas
          subsequentes. O prazo de ressarcimento e, ainda, a cobrança das
          parcelas remanescentes, após o estorno integral do valor do
          Produto no cartão de crédito do Usuário será realizado pela
          Rommanel, são de responsabilidade da administradora do cartão.
        </p>

        <p>
          Em compras pagas com boleto bancário ou débito em conta, o estorno
          será feito na conta corrente do Usuário, em até 10 (dez) dias
          úteis após o recebimento do produto no Centro de Distribuições da
          Rommanel. Não será concedido crédito a terceiros.
        </p>

        <p>
          Em compras realizadas com Paypal, o valor da compra será devolvido
          pela Rommanel, em até 30 dias do recebimento do produto, e este
          entrará em contato com Você para a respectiva devolução,
          dependendo da forma de pagamento escolhida.
        </p>

        <p>
          Não é possível devolver valores de compras realizadas com Cartão
          Presente ou Cartão de Compras.
        </p>

        <p>
          EM NENHUMA HIPÓTESE OS VALORES REFERENTES AOS TRIBUTOS OU TAXAS
          INCIDENTES SOBRE A COMPRA E VENDA, ENTREGA OU DEVOLUÇÃO DE
          PRODUTOS IMPORTADOS SERÃO OBJETO DE RESSARCIMENTO AO VOCÊ.
          EVENTUAIS DEMANDAS COM TAL OBJETO DEVERÃO SER DIRECIONADAS AOS
          ÓRGÃOS GOVERNAMENTAIS ARRECADADORES.
        </p>

        <p>
          <strong>Direito de Arrependimento.</strong> À você é facultado o
          exercício do direito de arrependimento/desistência da compra,
          hipótese na qual deverão ser observadas as seguintes condições:
          (i) O prazo de desistência da compra do produto é de até 07 (sete)
          dias corridos, a contar da data do seu recebimento; e (ii) O
          produto deve ser encaminhado na embalagem original, sem indícios
          de uso, sem violação do lacre original do fabricante, acompanhado
          da primeira via da Nota Fiscal, manual e todos os seus acessórios.
        </p>

        <p>
          Você deverá solicitar a devolução por meio do SAC ou diretamente
          pelo
          <i>E-commerce</i>, no Painel de Controle, no tópico referente a
          Trocas e Devoluções, seguindo os passos de preenchimento
          obrigatório. Após o recebimento do produto no Centro de
          Distribuição, a Rommanel verificará se as condições acima foram
          atendidas. Em caso afirmativo, providenciará a restituição do
          valor total da compra ao Usuário. Em caso negativo, o Usuário será
          contatado, sendo certo que este pode ser demandado a arcar com os
          valores de frete despendidos pelo <i>E-commerce</i>.
        </p>
      </section>
      <br />

      <section>
        <h4>
          &nbsp;4. &nbsp;&nbsp; Aplicativos Meu Mundo Rommanel e Academia
          Rommanel
        </h4>

        <p>
          <strong>Acesso aos Aplicativos.</strong> O acesso aos Aplicativos
          pode ser feito para Android e iOS (“Aplicativo”) nas lojas
          <i>App Store</i> e <i>PlayStore</i>. O uso de tais portais de
          conteúdo, ainda que apenas para obter os Aplicativos, está sujeito
          às regras próprias dos seus respectivos fornecedores.
        </p>

        <p>
          O acesso à Academia Rommanel é realizado através do link
          <a href="https://academia.rommanel.com.br" target="_blank">https://academia.rommanel.com.br</a>, Você poderá
          personalizá-lo, incluindo informações a seu
          respeito. Assim, é fundamental que você inclua apenas informações
          verdadeiras e completas, sobre as quais você efetivamente possua
          direitos.
        </p>

        <p>
          <strong>Condutas e obrigações do Usuário.</strong>Para utilizar os
          Aplicativos e Academia Rommanel, o Usuário deve efetuar um
          registro e manter uma conta ativa. O Usuário deve ter pelo menos
          18 anos de idade para acessar e utilizar os Aplicativos.
        </p>

        <p>
          O registro para obtenção de uma conta exige o fornecimento de
          determinados dados pessoais à Rommanel, tais como Nome, Data de
          Nascimento, Gênero, Endereço, CPF e e-mail. Esses dados somente
          serão compartilhados para as atividades dos Aplicativos. Para
          maiores informações, acesse nosso Aviso de Privacidade,
          disponibilizado no link
          <a href="https://www.rommanel.com.br/Institucional/aviso-de-privacidade"
            target="_blank">https://www.rommanel.com.br/Institucional/aviso-de-privacidade</a>
        </p>

        <p>
          Você concorda em manter as informações referentes a sua conta
          completas e atualizadas, caso contrário poderá ficar
          impossibilitado de acessar e usar os Aplicativos e seus Serviços.
          O Usuário também é responsável pela segurança e confidencialidade
          de seu login e senha. Salvo autorização por escrito da Rommanel,
          Você somente pode ter uma única Conta associada ao seu CPF.
        </p>

        <p>
          Ainda, os Aplicativos poderão solicitar acesso à sua
          geolocalização, por meio do GPS de seu aparelho telefônico, bem
          como permissão de uso de
          <i>Wireless Personal Area Networks</i> – PANs (“Bluetooth”), com o
          intuito de aprimorar o atendimento ao Usuário e lhe disponibilizar
          conteúdo relevante.
        </p>

        <p>
          De qualquer maneira, Você pode desabilitar as ferramentas de
          geolocalização e Bluetooth, se assim desejar, mas, neste caso, os
          Aplicativos poderão perder funcionalidades importantes que podem
          ser úteis para o seu pleno funcionamento, considerando as
          finalidades dele esperadas.
        </p>

        <p>
          A Rommanel se reserva o direito de utilizar todos os meios válidos
          e possíveis para identificar o Usuário, bem como de solicitar
          dados adicionais e documentos que estime serem pertinentes a fim
          de conferir os dados pessoais informados, com o intuito de
          confirmar a identidade do Usuário e garantir maior segurança às
          transações realizadas por meio dos Aplicativos.
        </p>

        <p>
          Você não pode autorizar terceiros a acessarem ou utilizarem a sua
          conta, nem pode ceder ou por outra forma transferir a sua conta
          para qualquer outra pessoa física ou jurídica.
        </p>

        <p>
          Além disso, Você aceita cumprir todas as leis aplicáveis durante a
          utilização dos Serviços, incluindo mas não se limitando ao Código
          de Defesa do Consumidor (Lei nº 8.078/90) e a Lei Geral de
          Proteção de Dados (Lei nº 13.709/18), e só pode utilizar os
          Serviços para fins legítimos. Durante a utilização dos Serviços,
          Você declara não praticará qualquer conduta ilegal ou proibida.
        </p>

        <p>
          <strong>Condutas proibidas.</strong> O Usuário fica proibido, sob
          pena de suspensão ou cancelamento da sua conta, de: (i) utilizar
          os Aplicativos e sites para finalidades ilícitas; (ii) submeter
          aos Aplicativos e sites, falsamente, dados de terceiros ou dados
          próprios incorretos; (iii) submeter aos Aplicativos e sites dados
          protegidos por direitos autorais dos quais não possa dispor; (iv)
          submeter aos Aplicativos e sites qualquer tipo de conteúdo ilegal,
          imoral ou incompatível com o Serviço, tais como: informações
          falsas, material preconceituoso, racista, ofensivo, injurioso,
          difamatório, calunioso, pornográfico, relacionado a menores de
          idade em qualquer aspecto e em especial pornografia ou exploração
          infantil, além de qualquer informação, ferramenta, software ou
          conhecimento que permita a um Usuário ou terceiro violar, de
          qualquer forma, os Termos Igual tratamento será concedido a
          qualquer tipo de arquivo, sítio eletrônico, mídia ou informação
          inserida de forma criptografada, protegida por senha ou de outro
          modo não imediatamente identificado como material ilícito.
        </p>

        <p>
          <strong>Integridade do Aplicativo e violações aos Termos.</strong>
          Os Usuários fica proibido, igualmente e sob as mesmas penas de
          todas condutas proibidas, de: (i) modificar, apagar, sabotar ou de
          qualquer forma violar, ou auxiliar, incentivar ou facilitar a
          terceiro a violação dos Aplicativos ou o Serviço conectado
          subjacente; (ii) violar conteúdo protegido por direitos de
          propriedade intelectual da Rommanel ou de qualquer afiliado,
          subcontratado ou parceiro; (iii) não buscar, abordar ou aceitar
          abordagem de terceiro para, de qualquer forma utilizar o Serviço
          ou qualquer de seus componentes para fraudar os Termos ou violar a
          Lei.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 5. &nbsp;&nbsp; Privacidade, coleta e uso de dados</h4>

        <p>
          <strong>Privacidade.</strong> Ao utilizar os Sites e os
          Aplicativos, o Usuário insere diversos dados, os quais podem
          incluir dados pessoais e dados pessoais sensíveis. A Rommanel está
          comprometida em garantir a confidencialidade e a segurança das
          suas informações, por isso elaboramos o Aviso de Privacidade,
          disponibilizado para o Usuário no endereço eletrônico
          <a href="https://www.rommanel.com.br/Institucional/aviso-de-privacidade."
            target="_blank">https://www.rommanel.com.br/Institucional/aviso-de-privacidade</a>.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 6. &nbsp;&nbsp; Propriedade Intelectual</h4>

        <p>
          <strong>Informações do Usuário.</strong> A Rommanel permite que o
          Usuário submeta, carregue, publique ou por outra forma
          disponibilize à Rommanel Informações do Usuário através dos
          Aplicativos para cumprimento das finalidades dos Serviços.
          Quaisquer informações do Usuário fornecidas por ele continuam a
          ser propriedade do mesmo.
        </p>

        <p>
          <strong>Violação de propriedade intelectual de terceiros.</strong>
          Você é responsável pelo conteúdo inserido nos Aplicativos, e
          declara que tem o direito ou as necessárias licenças para
          utilizar-se desse conteúdo. Na eventualidade de tal presunção
          provar-se falsa, mediante descoberta fortuita ou notificação do
          interessado, o conteúdo em questão será imediatamente removido dos
          Aplicativos, sendo notificado o Usuário responsável sobre a
          remoção, exceto no caso de determinação judicial em contrário.
        </p>

        <p>
          <strong>Direito de regresso.</strong> A Rommanel não se
          responsabiliza pela violação, por parte do Usuário, de direitos de
          propriedade intelectual de terceiro, cabendo ao Usuário isentar a
          Rommanel de qualquer responsabilidade por tal violação. De
          qualquer modo, a Rommanel terá direito integral de regresso contra
          o Usuário, quando o dano a ser indenizado decorra direta ou
          indiretamente de sua culpa. A Rommanel declara que não realiza e
          não está obrigada a realizar controle prévio do conteúdo submetido
          aos Aplicativos.
        </p>

        <p>
          <strong>Notificações sobre violações.</strong> Quaisquer
          notificações sobre violações de propriedade intelectual podem ser
          encaminhadas para
          <a href="mailto:atendimento@rommanel.com.br">atendimento&#64;rommanel.com.br</a>.
        </p>

        <p>
          <strong>Propriedade intelectual da Rommanel.</strong> A Rommanel é
          titular exclusiva de todos os direitos, morais e patrimoniais,
          incidentes ou eventualmente incidentes sobre aspectos visuais e/ou
          artísticos dos Aplicativos, bem como o código-fonte, os elementos
          técnicos, de design, de processos, relatórios, e outros que nos
          ajudam a caracterizar os Aplicativos, a aplicação em si, todos os
          sinais distintivos, marcas, logos e qualquer material passível de
          proteção por direitos autorais ou propriedade industrial. Para
          permitir sua utilização dos Aplicativos, a Rommanel concede, pela
          duração da manutenção da sua Conta dos Aplicativos, uma licença de
          uso, limitada, revogável, intransferível, não exclusiva e não
          passível de sublicenciamento, dos direitos de propriedade
          intelectual necessários à utilização dos Aplicativos , o que não
          inclui direitos de reprodução, alteração, comunicação ao público
          ou qualquer forma de distribuição. É estritamente proibido
          qualquer tipo de cópia, alteração ou redistribuição do aplicativo,
          do sítio eletrônico, do código-fonte ou de qualquer elemento
          relativo à Propriedade Intelectual, em parte ou no todo. O Usuário
          também concorda que não vai fazer, tentar fazer, ou ajudar alguém
          a fazer nenhum tipo de engenharia reversa, incluindo
          descompilação, desassemblagem ou outras técnicas, ou qualquer
          tentativa de acesso ao código fonte e estrutura do banco de dados,
          em relação aos Aplicativos. É proibido utilizar e/ou reproduzir,
          sob qualquer pretexto, as marcas, desenhos gráficos, logomarca e
          expressões da marca “ROMMANEL”, a qual reconhece que não possui
          qualquer direito sobre à mesma. É vedada a criação de quaisquer
          materiais de comunicação com a marca e logotipo Rommanel e/ou a
          reprodução de quaisquer materiais de divulgação da Rommanel que
          contenham a imagem de artistas, de modelos ou de quaisquer imagens
          de terceiros. Da mesma forma, é vedada a utilização da marca
          Rommanel ou de parceiros ou licenciados da Rommanel, em associação
          com outras marcas não autorizadas.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 7. &nbsp;&nbsp; Exceções de Garantias</h4>

        <p>
          <strong>Os Serviços são prestados "como são" e "nos termos em que se
            encontram disponíveis".</strong>
          A Rommanel não aceita quaisquer declarações e garantias,
          expressas, implícitas ou legais, com relação aos Serviços ou
          Aplicativo, não expressamente estabelecidas nestes Termos.
        </p>

        <p>
          A Rommanel não faz qualquer declaração e nem presta qualquer
          garantia relativamente à confiabilidade, tempestividade,
          qualidade, adequação ou disponibilidade dos Aplicativos e dos
          Sites serão isentos de interrupções ou de erros.
        </p>

        <p>
          <strong>Acesso à rede e dispositivos.</strong> Você é responsável
          por obter o acesso à rede de dados necessário para usar os
          Serviços. As taxas e encargos de sua rede de dados e mensagens
          poderão se aplicar se você acessar ou usar os Serviços de um
          dispositivo sem fio e você será responsável por essas taxas e
          encargos. Você é responsável por adquirir e atualizar os
          equipamentos e dispositivos necessários para acessar e usar os
          Serviços e quaisquer de suas atualizações. A Rommanel não garante
          que os Serviços, ou qualquer parte deles, funcionarão em qualquer
          equipamento ou dispositivo em particular. Além disso, os Serviços
          poderão estar sujeitos a mau funcionamento e atrasos inerentes ao
          uso da Internet e de comunicações eletrônicas.
        </p>

        <p>
          <strong>Ação de agentes maliciosos.</strong> Você está ciente de
          que, apesar de toda a segurança que nos esforçarmos em
          implementar, não há garantia contra a ação de hackers, crackers e
          outros agentes maliciosos que podem tentar ou desejem violar o
          Aplicativo por diversão ou para ganho próprio ou de terceiros, e
          você concorda que, nestes casos, não haverá qualquer
          responsabilidade da Rommanel pelos danos daí decorrentes.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 8. &nbsp;&nbsp; Exceções de Responsabilidade</h4>

        <p>
          <strong>Danos pelo uso do Serviço</strong>. A Rommanel, suas
          afiliadas, subcontratantes e parceiros não são responsáveis por
          qualquer tipo de danos oriundos de, mas não limitado a:
          <strong>(i)</strong> vulnerabilidades de segurança nos
          dispositivos de acesso aos Aplicativos,
          <strong>(ii)</strong> danos causados por ações ou omissões do
          Usuário; <strong>(iii)</strong> utilização indevida dos
          dispositivos; <strong>(iv)</strong> interrupções ou erros na
          transmissão de dados pela internet ou telefonia celular;
          <strong>(v)</strong> intervenção de qualquer espécie do Poder
          Público ou ainda <strong>(vi)</strong> força maior ou caso
          fortuito. Os Aplicativos são ofertados tal como se encontra e o
          uso feito dos Serviços é de responsabilidade do próprio Usuário.
          <ins>
            A ROMMANEL NÃO GARANTE DECLARA OU ASSEGURA QUE O USO DOS
            APLICATIVOS SERÝ ININTERRUPTO OU LIVRE DE ERROS, PERDAS, ATAQUE,
            VÝRUS, OU QUALQUER INCIDENTE DE SEGURANÇA. O USUÝRIO ESTÝ CIENTE
            QUE A ROMMANEL PODERÝ REMOVER POR PERÝODOS INDEFINIDOS OU
            CANCELAR ESSES APLICATIVOS A QUALQUER MOMENTO.
          </ins>
        </p>

        <p>
          <strong>Danos sofridos por interferência de terceiros.</strong>
          A Rommanel, suas afiliadas, subcontratantes e parceiros não são
          responsáveis por quaisquer danos, sejam eles de natureza física,
          moral, estética, econômica, acidental, punitiva, perda de chance,
          lucros cessantes, perda de dados, ou qualquer outra que você ou
          terceiro venham a experimentar em razão da interferência ilegítima
          de terceiros nos Serviços prestados.
        </p>

        <p>
          <strong>Consultor(a) independente Rommanel.</strong> O(a)
          Consultor(a) independente Rommanel não é representante, agente,
          tampouco procurador do Distribuidor Licenciado e/ou da empresa
          fabricante e/ou proprietária da marca ROMMANEL, estando-lhe
          proibido comprometer-se em nome da(s) mesma(s), bem como utilizar
          e/ou reproduzir, sob qualquer pretexto, as marcas, desenhos ou
          elementos gráficos, logomarcas e expressões da marca ROMMANEL,
          sobre a qual reconhece não possuir qualquer direito. O(a)
          Consultor(a) independente Rommanel não possui relação de emprego
          com a Rommanel ou seus Distribuidores Licenciados, sob qualquer
          modalidade, nem como agente exclusivo.
        </p>

        <p>
          <strong>Direito de regresso.</strong> Caso a Rommanel seja
          implicada de qualquer maneira em cobranças extrajudiciais ou
          qualquer outra medida judicial em decorrência de danos causados
          por um Usuário, este se obriga a intervir nos procedimentos em
          trâmite, de modo a isentar a Rommanel de qualquer possível
          resultado negativo. Ainda que tais esforços não sejam suficientes,
          a Rommanel terá direito integral de regresso contra seus Usuários
          quando o dano a ser indenizado decorra direta ou indiretamente de
          culpa de um Usuário.
        </p>

        <p>
          <strong> <i> Acesso a logs.</i> </strong> A Rommanel não tem a
          obrigação de fornecer e não fornecerá eventuais logs ou registros
          de conexão e de acesso ao Aplicativo que não digam respeito ao
          próprio Usuário solicitante, exceto se assim determinado por
          decisão judicial.
        </p>

        <p>
          <strong> <i> Back-Up. </i> </strong>O Usuário reconhece que a
          Rommanel não é obrigada a disponibilizar aos Usuários o back-up de
          seus dados, inseridos diretamente por eles nos Aplicativos.
        </p>

        <p>
          <strong>Isenção De Responsabilidade Comercial e Fiscal.</strong>
          Você é totalmente responsável pela compra e venda dos produtos,
          independentemente do uso ou acesso do Aplicativo por Você. Ainda,
          Você é o responsável integral pela regularidade comercial e fiscal
          da venda e compra dos produtos, não tendo a Rommanel, suas
          afiliadas, subcontratantes e parceiros quaisquer responsabilidades
          por qualquer tipo de danos oriundos de tal oferta de produtos
          feita por Você.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 9. &nbsp;&nbsp; Rescisão Contratual</h4>

        <p>
          Os Termos, em regra, estão vigentes desde o registro realizado
          pelo Usuário até seu desligamento dos Serviços, seja ele realizado
          a pedido de uma das partes, por comum acordo ou por
          descontinuidade do serviço.
        </p>

        <p>
          <strong>Rescisão por violação contratual ou legal.</strong>
          Caso Você viole qualquer disposição dos Termos, do Aviso de
          Privacidade ou da Lei vigente, a Rommanel fica autorizada, à sua
          discrição, a suspender ou cancelar a conta do infrator.
        </p>

        <p>
          <strong>Rescisão a pedido do Usuário</strong>. Caso Você queira
          solicitar o encerramento da sua conta, deverá solicitar através do
          e-mail:
          <a href="mailto:privacidade@rommanel.com.br">privacidade&#64;rommanel.com.br</a>
          e ela será excluída no prazo de 07 dias a contar do pedido, feito
          por meio dos próprios Aplicativos. Após este prazo, todas as
          informações referentes à Conta serão apagadas definitivamente,
          exceto quando tais dados forem coletados ou tratados sobre outras
          bases legais ou quando a Rommanel seja obrigada, por Lei, a
          armazenar determinados dados.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 10. &nbsp;&nbsp; Disposições Gerais</h4>

        <p>
          <strong>Lei aplicável.</strong> EEstes Termos são regidos pela lei
          brasileira, sendo esta integralmente aplicável a quaisquer
          disputas que possam surgir sobre a interpretação ou execução deste
          contrato, assim como qualquer outra disputa que envolva direta ou
          indiretamente a prestação dos Serviços.
        </p>

        <p>
          <strong>Tolerância.</strong>Eventuais omissões ou meras
          tolerâncias das partes no exigir o estrito e pleno cumprimento dos
          Termos ou de prerrogativas decorrentes dele ou de lei, não
          constituirão novação ou renúncia, nem afetarão o exercício de
          quaisquer direitos, que poderão ser plena e integralmente
          exercidos, a qualquer tempo.
        </p>

        <p>
          <strong>Independência das cláusulas.</strong>Caso se perceba que
          uma disposição é nula, as disposições restantes dos Termos
          permanecerão em pleno vigor e um termo válido substituirá o termo
          nulo, refletindo nossa intenção, tanto quanto possível.
        </p>

        <p>
          <strong>Eleição de foro.</strong> Em todos os casos, esgotado o
          procedimento anterior sem solução da controvérsia, as partes optam
          desde já por reconhecer a competência da Justiça Estadual
          brasileira, elegendo o foro da Comarca de residência do Usuário.
        </p>
      </section>
      <br />

      <section>
        <h4>&nbsp; 11. &nbsp;&nbsp; Fale com a Rommanel</h4>

        <p>
          Obrigado por ler nossos Termos. Se tiver quaisquer dúvidas
          relativamente a estes Termos, entre em contato através do
          formulário "Fale com a Rommanel" no endereço eletrônico
          <a href="mailto:privacidade@rommanel.com.br">privacidade&#64;rommanel.com.br</a>.
        </p>
      </section>
    </article>

    <section class="modal-footer">
      <button type="button" class="btn btn-light" (click)="d('')">
        Voltar
      </button>
    </section>
  </ng-template>

  <ng-template #politica let-c="close" let-d="dismiss">
    <section class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="d('')">
        <span aria-hidden="true">&times;</span>
      </button>
    </section>
    <article class="modal-body">
      <h2>AVISO DE PRIVACIDADE</h2>
      <br />

      <h6>Versão atualizada [14/12/2023]</h6>

      <p>Seja bem-vindo aos Sites e Aplicativos Rommanel!</p>
      <br />

      <p>
        A Rommanel quer que Você tenha controle sobre seus dados e
        também se preocupa com a sua privacidade e com a segurança
        das suas informações. Assim, este Aviso de Privacidade irá
        te contar quais informações suas nós coletamos e como as
        utilizamos nos Sites e Aplicativos Rommanel.
      </p>
      <br />

      <p>
        É importante que Você leia este Aviso.
      </p>
      <br />

      <ol>
        <li>DEFINIÇÕES</li>

        <li>O QUE É ESTE DOCUMENTO?</li>

        <li>
          QUEM É A RESPONSÝVEL PELO TRATAMENTO DOS DADOS PESSOAIS
          (CONTROLADOR)?
        </li>

        <li>ATUALIZAÇÕES DESTA POLÝTICA DE PRIVACIDADE</li>

        <li>
          QUAIS DADOS PESSOAIS TRATAMOS E PARA QUAIS FINALIDADES
        </li>

        <li>COOKIES</li>

        <li>POR QUANTO TEMPO TRATAREMOS OS SEUS DADOS</li>

        <li>COMUNICAÇÃO E USO COMPARTILHADO</li>

        <li>EXERCÝCIO DE DIREITOS</li>

        <li>COMO PROTEGEMOS SEUS DADOS PESSOAIS</li>

        <li>COMO RESPONDEMOS AOS REQUERIMENTOS LEGAIS</li>

        <li>VIGÊNCIA E RESCISÃO</li>

        <li>DISPOSIÇÕES GERAIS</li>
      </ol>

      <br />

      <section>
        <strong>1.DEFINIÇÕES</strong>
        <p>
          As disposições constantes nesta Política, sempre que
          usadas com a primeira letra em maiúscula, no plural ou no
          singular, terão o significado abaixo apresentado:
        </p>

        <p>
          <strong>“Aplicativos” e “Site”</strong> são os aplicativos “Meu
          Mundo Rommanel” que auxilia Você na gestão do seu negócio,
          “Academia Rommanel”, que tem conteúdo educacional, e a
          Plataforma “Minha Loja Rommanel”, destinada a aperfeiçoar
          sua atuação como um de nossos(as) Consultores independentes
          Rommanel ou Parceiro Rommanel.
        </p>

        <p>
          <strong>“Cookies”</strong> refere-se aos pequenos
          arquivos-texto, colocados e armazenados no computador,
          smartphone ou outro dispositivo da internet quando Você
          visita as nossas plataformas web.
        </p>

        <p>
          <strong>“Dado Pessoal”</strong> refere-se à informação
          relacionada à pessoa física identificada ou identificável.
        </p>

        <p>
          <strong>“Encarregado”</strong> é a pessoa do nosso time
          responsável pelos assuntos que envolvem proteção de Dados
          Pessoais. Se Você tiver qualquer dúvida sobre como os seus
          Dados Pessoais são tratados por nós ou, ainda, quiser
          exercer qualquer um dos seus direitos enquanto titular de
          dados, contate o nosso Encarregado por meio do canal
          específico disponibilizado neste Aviso.
        </p>

        <p>
          <strong>“Titular”</strong>  refere-se à pessoa física a
          quem se referem os Dados Pessoais que são objeto de
          tratamento, por exemplo nome, CPF, e-mail, endereço,
          dentre outros.
        </p>

        <p>
          <strong>“Tratamento”</strong> significa qualquer ação
          realizada com um Dado Pessoal, como por exemplo, a coleta,
          o descarte, o armazenamento, o processamento, a agregação,
          dentre outras ações;
        </p>

        <p>
          <strong>“Sites”</strong> refere-se ao Site Rommanel, que
          contém Vitrine Virtual (disponível também para o público)
          e contato para se tornar um Consultor Rommanel (Meu
          Negócio Rommanel) e ao E-Commerce Rommanel, para que Você
          possa adquirir seus Produtos Rommanel.
        </p>

        <p>
          <strong>“Usuário ou Você”</strong>refere-se a você,
          Consultor Rommanel que utiliza o Aplicativo para agregar
          valor ao seu negócio, ou a você que é visitante/usuário de
          nosso Site.
        </p>
      </section>
      <br />

      <section>
        <strong>2.O QUE É ESTE DOCUMENTO?</strong>
        <p>
          Este documento é o Aviso de Privacidade destinado aos
          Usuários de nossos Sites e/ou Aplicativos, que contém as
          regras e informações de como a Rommanel trata os seus
          Dados Pessoais.
        </p>
      </section>
      <br />

      <section>
        <strong>
          3.QUEM É A RESPONSÝVEL PELO TRATAMENTO DOS DADOS PESSOAIS
          (CONTROLADOR)?
        </strong>
        <p>
          MMA INDUSTRIA E COMERCIO DE BIJUTERIAS LTDA, inscrita no
          CNPJ sob o nº 04.942.899/0001-12, com sede à Rua Santa
          Monica, nº 1733, Capuava, CEP 06.715-865 (“Rommanel”).
        </p>
      </section>
      <br />

      <section>
        <strong>4.ATUALIZAÇÕES DESTE AVISO DE PRIVACIDADE </strong>
        <p>
          É muito importante que os Usuários de nossos Sites e
          Aplicativos façam a leitura deste Aviso. A Rommanel
          reserva-se ao direito de alterar este Aviso a qualquer
          momento, e manterá publicado nos Sites e Aplicativos
          sempre a versão mais recente. Se fizermos alguma alteração
          neste Aviso, Você será avisado, de no momento em que entrar
          no Site ou Aplicativo com uma mensagem.
        </p>

        <p>
          Para verificar a data da versão em vigor, verifique a
          “Data de atualização” no início desse documento.
        </p>

      </section>
      <br />

      <section>
        <strong>
          5.QUAIS DADOS PESSOAIS TRATAMOS E PARA QUAIS FINALIDADES
        </strong>
        <br />

        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="align-middle text-center">
                Como obtivemos o Dado Pessoal
              </th>
              <th class="align-middle text-center">Quais dados</th>
              <th class="align-middle text-center">
                Para qual finalidade
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-middle">Você nos ofereceu</td>

              <td>
                <ul>
                  <li>
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;<ins>Dados cadastrais:</ins></strong>
                    nome, assinatura, CPF, RG, CTPS, PI/INSS,
                    e-mail, data de nascimento, endereço, CEP,
                    telefone, Usuário em Aplicativo de mensagens,
                    redes sociais, título de reservista e estado
                    civil.
                    <br />
                    <br />
                  </li>

                  <li>
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;<ins>Dados comportamentais:</ins></strong>
                    Hábitos de consumo, tendência de compra,
                    navegação – histórico de utilização de sistema e
                    histórico em redes sociais
                    <br />
                    <br />
                  </li>

                  <li>
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;<ins>Dados profissionais:</ins></strong>
                    função, profissão, remuneração
                    <br />
                    <br />
                  </li>

                  <li>
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;<ins>Dados financeiros:</ins></strong>dados bancários (agência e
                    número de conta),
                    dados do cartão de crédito, histórico de
                    crédito,
                    <br />
                    <br />
                  </li>
                  <li>Fotos e vídeos</li>
                </ul>
              </td>

              <td>
                <ul>
                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Recrutamento de
                      novos colabores;
                    </strong>
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Relação
                      comercial</strong>
                    (atualização dos cadastros de clientes, usuários
                    e parceiros; execução de contratos; atendimento
                    ao cliente; acesso ao Site e aplicativos;
                    andamento do seu pedido com Lojas Licenciadas
                    Rommanel);
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;Comunicação e
                      marketing
                    </strong>
                    (ações de marketing; envio de brindes; cartão de
                    visita; execução de campanhas regulamentadas,
                    eventos destinados a parceiros e clientes;
                    atendimento aos consumidores);
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Operações
                      internas</strong>
                    (métricas de avalições internas);
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Cumprimento de
                      obrigações legais e exercício dos nossos
                      direitos.
                    </strong>
                    <br />
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="align-middle">
                Coletamos automaticamente pelo uso dos Sites e
                Aplicativos
              </td>
              <td>
                <ul>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Tipo de dispositivo
                    utilizado
                    <br />
                  </li>

                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; IP de Conexão (com data
                    e hora de acesso)
                    <br />
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Localização Geográfica
                    aproximada
                    <br />
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Números de
                    identificação do seu dispositivo
                    <br />
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Navegação no Aplicativo
                    <br />
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Software ou navegador
                    utilizado para acessar o Aplicativo
                    <br />
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Sistema Operacional e
                    sua versão
                    <br />
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;&nbsp; Cookies
                    <br />
                  </li>
                </ul>
              </td>

              <td>
                <ul>
                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Facilitar suporte
                      técnico;
                    </strong>
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Melhoria de
                      navegabilidade;
                    </strong>
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Conectar a revenda
                      ao consumidor mais próximo;
                    </strong>
                    <br />
                    <br />
                  </li>

                  <li style="list-style-type: '❖'">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp; Cumprimento de
                      obrigações legais e exercício dos nossos
                      direitos.
                    </strong>
                    <br />
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>
            Algumas funções que permitem a coleta de dados
            automaticamente, como de geolocalização, podem ser
            habilitadas, se assim você desejar. Entretanto, em caso
            de estarem desabilitadas, o Aplicativo não funcionará
            plenamente, o que impedirá o uso de todas as suas
            funcionalidades.
          </strong>
        </p>
      </section>
      <br />

      <section>
        <strong>6.COOKIES</strong>
        <p>
          Nós utilizamos cookies para permitir a sua correta
          identificação, além de melhorar a qualidade de informações
          oferecidas no nosso portal aos Usuários. Cookie é um
          pequeno arquivo adicionado ao seu dispositivo ou
          computador para fornecer uma experiência personalizada de
          acesso. Os cookies ajudam a analisar o tráfego de internet
          e nos permitem saber quando Você visitou um site
          específico. Destacamos que os cookies não dão acesso ao
          seu computador ou revelam informações além daquelas que
          Você escolhe compartilhar conosco.
        </p>

        <p>
          <strong>São essas as categorias de cookies que poderão ser
            utilizados pela Rommanel e suas finalidades:
          </strong>
        </p>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Categorias de Cookies</th>
              <th scope="col">Quais suas funções?</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th class="align-middle text-center" style="font-weight: initial">
                Essenciais
              </th>
              <td>
                <p>
                  São fundamentais para as funções básicas de
                  navegação no nosso Site, tal como acessar áreas
                  seguras utilizando o login.
                  <br />
                  Sem os cookies essenciais, o nosso Site ou alguma
                  de sua funcionalidade não opera corretamente,
                  prejudicando a navegação
                </p>
              </td>
            </tr>

            <tr>
              <th class="align-middle text-center" style="font-weight: initial; width: 15em">
                Analíticos
              </th>
              <td>
                <p>
                  Permitem coletar informações sobre a interação do
                  Usuário no Site, coletando e relatando informações
                  anonimamente. Com eles, analisamos como nossos
                  serviços, produtos e comunicações estão sendo
                  acessados, utilizados e se estão funcionando
                  corretamente
                </p>
              </td>
            </tr>

            <tr>
              <th class="align-middle text-center" style="font-weight: initial">
                Funcionais
              </th>
              <td>
                <p>
                  Nos permitem relembrar as suas preferências, como
                  o autopreenchimento de campos do Site, de forma
                  que não seja necessário voltar e configurar o Site
                  a cada vez que o visita. Além disso, possibilitam
                  que você visualize vídeos, utilize campos para
                  comentários, entre outros.
                </p>
              </td>
            </tr>

            <tr>
              <th class="align-middle text-center" style="font-weight: initial">
                Marketing
              </th>
              <td>
                <p>
                  São utilizados para fornecer ao Usuário conteúdo
                  do seu interesse. A partir deles, podemos
                  direcionar a publicidade com maior assertividade.
                  Eles também nos ajudam a coletar dados
                  consolidados para fins de auditorias, pesquisas e
                  relatórios de desempenho para anunciantes.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Assim, nós usamos cookies, por exemplo, para contar
          quantos visitantes recebemos no nosso Site, qual
          publicidade teve mais cliques e visualizações, quais
          conteúdos são mais acessados, e possibilitar que Você
          navegue sem complicação e de forma ágil. Nós
          contabilizamos o clique identificando juntamente o ID do
          Usuário que clicou, no caso de Usuários cadastrados.
        </p>

        <p>
          Contudo, Você poderá desabilitar os cookies nas
          configurações do seu navegador, mas alguns deles são
          necessários para possibilitar a utilização do nosso Site.
        </p>

        <p>
          Abaixo, seguem os links dos principais navegadores de
          internet utilizados no mercado, para auxiliá-lo na gestão
          dos cookies:
        </p>
        <br />

        <ul>
          <li>
            <strong>&nbsp;&nbsp;&nbsp;&nbsp; Google Chrome:</strong>
            <a href="https://support.google.com/chrome/answer/95647?hl=pt-BR">
              https://support.google.com/chrome/answer/95647?hl=pt-BR
            </a>
            <br />
          </li>

          <li>
            <strong>&nbsp;&nbsp;&nbsp;&nbsp; Internet Explorer:</strong>
            <a
              href="https://support.microsoft.com/pt-br/topic/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">
              https://support.microsoft.com/pt-br/topic/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11
            </a>
            <br />
          </li>

          <li>
            <strong>&nbsp;&nbsp;&nbsp;&nbsp; Firefox:</strong>
            <a href="https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento">
              https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento
            </a>
            <br />
          </li>

          <li>
            <strong>&nbsp;&nbsp;&nbsp;&nbsp; Safari:</strong>
            <a href="http://safari.helpmax.net/ps/privacidade-e-seguranca/como-remover-cookies/">
              http://safari.helpmax.net/ps/privacidade-e-seguranca/como-remover-cookies/
            </a>
            <br />
          </li>
        </ul>
      </section>

      <section>
        <strong>7.POR QUANTO TEMPO TRATAREMOS OS SEUS DADOS
        </strong>
        <p>
          Reteremos seus Dados Pessoais pelo tempo que for
          necessário para cumprir a finalidade para a qual foram
          coletados, a menos que um período de retenção mais longo
          seja necessário para cumprir obrigações legais ou
          resguardar direitos, os nossos e os seus.
        </p>

        <p>
          Para determinar o período de retenção de seus Dados
          Pessoais, nos baseamos nos seguintes critérios:
        </p>

        <ul>
          <li>
            Se temos uma obrigação legal ou contratual de reter os
            Dados Pessoais;
          </li>

          <li>
            Se os dados são necessários para resguardarmos os nossos
            direitos; e
          </li>

          <li>
            Se os Dados Pessoais são necessários para mantermos
            registros comerciais e financeiros precisos.
          </li>
        </ul>
      </section>
      <br />

      <section>
        <strong>8.COMUNICAÇÃO E USO COMPARTILHADO </strong>
        <p>
          <strong>Comunicação a operadores do Tratamento.</strong>A
          Rommanel poderá compartilhar os Dados Pessoais com
          prestadores de serviço, subcontratados ou outros
          fornecedores que atuarem, sob direção da Rommanel, no
          Tratamento dos Dados Pessoais, na condição de Operadores
          do Tratamento para fins de análise de dados e melhorias
          nos Aplicativos e Sites.
        </p>

        <p>
          <strong>Comunicação a terceiros.</strong>A Rommanel poderá
          compartilhar os Dados Pessoais com terceiros nas situações
          descritas abaixo:
        </p>

        <ul>
          <li style="list-style-type: none">
            a) para a empresa responsável pelo armazenamento e
            processamento dos dados; caso necessário;
          </li>
          <li style="list-style-type: none">
            b) para proteção dos interesses da Rommanel em qualquer
            tipo de conflito, inclusive demandas judiciais;
          </li>
          <li style="list-style-type: none">
            c) no caso de transações e alterações societárias
            envolvendo a Rommanel, hipótese em que a transferência
            das informações será necessária para a continuidade dos
            serviços e entrega dos produtos; ou
          </li>
          <li style="list-style-type: none">
            d) mediante ordem judicial ou pelo requerimento de
            autoridades administrativas que detenham competência
            legal para sua requisição, neste caso nos termos do
            artigo 10, §3º do Marco Civil da Internet (Lei
            12.965/2014).
          </li>
        </ul>

        <p>
          <strong>Oferta de Produtos Financeiros. </strong>Como Cliente
          Rommanel Você tem acesso a produtos financeiros e de crédito
          oferecidos por instituição parceira da Rommanel. Caso Você
          tenha interesse em receber ofertas de produtos financeiros e
          de crédito, é só autorizar no momento do cadastro ou no item
          “Gerenciar acessos”, no Menu dos Aplicativos e Sites. Neste
          caso, compartilharemos seus dados pessoais, garantindo nível
          de segurança, para o recebimento de ofertas personalizadas e
          para proteção do crédito.
        </p>

        <p>
          <strong>Uso compartilhado com Lojas Licenciadas
            Rommanel.</strong>A Rommanel pode vir a realizar o uso compartilhado de
          seus Dados Pessoais com a Loja Licenciada Rommanel, a qual
          Você já realiza suas compras, a fim de mantermos os dados
          centralizados para o seu melhor atendimento.
        </p>

        <p>
          <strong>Transferência internacional.</strong>Em alguns
          casos, poderá haver transferência dos seus dados pessoais
          para fora do Brasil, para que as empresas do nosso grupo
          em outros países nos auxiliem a conduzir nossos negócios,
          ou para fornecedores e parceiros comerciais localizados no
          exterior, como nos Estados Unidos. Nessas situações,
          utilizaremos contratos e medidas para garantir a segurança
          dos dados.
        </p>
      </section>
      <br />

      <section>
        <strong>9.EXERCÝCIO DE DIREITOS </strong>
        Você, como Titular de Dados Pessoais, possui os seguintes
        direitos:
        <ul>
          <li>
            Saber se realizamos algum Tratamento com seus Dados
            Pessoais e quais dados são tratados;
          </li>
          <li>
            Corrigir ou solicitar a correção de dados incompletos,
            inexatos ou desatualizados;
          </li>
          <li>
            Solicitar a anonimização, bloqueio ou eliminação de
            dados desnecessários ou excessivos;
          </li>
          <li>
            Solicitar a portabilidade dos dados a outro fornecedor
            de produtos ou serviços similares;
          </li>
          <li>
            Obter informações sobre as entidades públicas ou
            privadas com as quais compartilhamos os seus dados;
          </li>
          <li>
            Quando a atividade de Tratamento necessitar do seu
            consentimento, Você pode se negar a consentir. Nesse
            caso, lhe informaremos sobre as consequências da não
            realização de tal atividade;
          </li>
          <li>
            Quando a atividade de Tratamento estiver baseada do seu
            consentimento, Você pode revogá-lo a qualquer momento; e
          </li>
          <li>
            Pedir a revisão de decisões automatizadas que afetem
            seus interesses.
          </li>
        </ul>

        <p>
          Caso Você tenha alguma solicitação, reclamação, pedido de
          informação ou qualquer outra demanda você pode entrar em
          contato com a Encarregada da Rommanel por meio do e-mail
          <a href="mailto:privacidade@rommanel.com.br">privacidade&#64;rommanel.com.br</a>
          .
        </p>

        <p>
          Por favor quando nos enviar o e-mail, envie dados de
          contato. Essas informações serão utilizadas para
          retornarmos sua solicitação e aprimorar nossos
          atendimentos.
        </p>

        <p>
          Eventualmente, outras informações ou documentos podem ser
          solicitados para o atendimento da requisição, caso haja
          dúvida sobre a veracidade das informações prestadas ou
          sobre a sua identidade.
        </p>
      </section>
      <br />

      <section>
        <strong>10.COMO PROTEGEMOS SEUS DADOS PESSOAIS </strong>
        <p>
          A Rommanel emprega seus melhores esforços para respeitar e
          proteger suas informações contra perda, roubo ou qualquer
          modalidade de uso indevido de suas informações, bem como
          contra acesso não autorizado, divulgação, alteração e
          destruição.
        </p>
        <p>
          Só tratamos os seus Dados Pessoais mediante alto grau de
          segurança, implementando as melhores práticas em uso na
          indústria para a proteção de dados, tais como técnicas de
          criptografia, monitoração e testes de segurança
          periódicos.
        </p>
      </section>
      <br />

      <section>
        <strong>11.COMO RESPONDEMOS AOS REQUERIMENTOS LEGAIS
        </strong>
        <p>
          Seus dados pessoais poderão ser compartilhados com
          autoridades públicas do Brasil ou de outro país (por
          exemplo, Poder Judiciário, Receita Federal, Tribunal de
          Contas), caso a Rommanel receba alguma requisição ou uma
          ordem judicial.
        </p>
      </section>
      <br />

      <section>
        <strong>12.VIGÊNCIA E RESCISÃO </strong>
        <p>Esta Política vigorará por prazo indeterminado.</p>

        <p>
          Você poderá, a qualquer momento, exercer seus direitos ou
          enviar dúvidas sobre o o conteúdo deste Aviso e Tratamento
          de seus Dados Pessoais por e-mail enviado à Rommanel no
          <a href="mailto:privacidade@rommanel.com.br">
            privacidade&#64;rommanel.com.br</a>.
        </p>

        <p>
          Caso deseje encerrar sua relação com a Rommanel, deverá
          enviar comunicado ao mesmo e-mail e sua conta será
          excluída no prazo de 7 dias a contar do pedido feito.
          Após este prazo, todas as informações referentes ao
          Usuário serão apagadas definitivamente, exceto quando a
          Rommanel seja obrigada, por lei, a armazenar determinados
          dados, ou, quando houver outra situação que permita a sua
          manutenção.
        </p>
      </section>
      <br />

      <section>
        <strong>13.DISPOSIÇÕES GERAIS </strong>
        <p>
          <strong>Lei aplicável.</strong> Este Aviso de Privacidade
          é regido pela lei brasileira, sendo esta integralmente
          aplicável a quaisquer disputas que possam surgir sobre a
          interpretação ou execução deste Aviso, assim como qualquer
          outra disputa que envolva direta ou indiretamente o uso
          dos Sites e dos Aplicativos pelos Usuários.
        </p>
      </section>
      <br />
    </article>

    <section class="modal-footer">
      <button type="button" class="btn btn-light" (click)="d('')">
        Voltar
      </button>
    </section>
  </ng-template>
</div>
