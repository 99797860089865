import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { provideEnvironmentNgxMask } from 'ngx-mask';


@NgModule({
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
  ],
  providers: [provideEnvironmentNgxMask()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UtilsModule { }
