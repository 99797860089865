import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lading-page-app-root',
  template: '<router-outlet></router-outlet>',
})
export class LandingPageAppComponent implements OnInit{

    constructor(private route: ActivatedRoute,) { }

    ngOnInit(): void { }
}
