import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from '../services/base.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilsModule } from '../utils/utils.module';
import { NgxMaskDirective } from 'ngx-mask';
import { Agendamento } from '../services/models/agendamento';
import { CommonModule, DatePipe } from '@angular/common';
import { Dispenser } from '../services/models/dispenser';
@Component({
  selector: 'app-agendamento',
  standalone: true,
  templateUrl: './agendamento.component.html',
  styleUrls: ['./agendamento.component.scss'],
  providers: [DatePipe],
  imports: [ReactiveFormsModule, NgbModule, NgxMaskDirective, UtilsModule, CommonModule]
})
export class AgendamentoComponent implements OnInit {
  spinner = false;
  agendamentoForm: FormGroup;
  agendamento: Agendamento;
  loja: Dispenser;
  selectedTipo: number | null = null;
  guid: string = '';
  urlReturn: string | null = null;

  constructor(
    protected baseService: BaseService,
    protected router: Router,
    protected toastr: ToastrService,
    protected fb: FormBuilder,
    protected activatedRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.setAgendamentoForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.baseService.getAgendamento(params['id']).subscribe(
        (response) => {
          this.agendamento = response;
          this.getLoja();
        },
        (error) => this.processFail(error)
      );
    });

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      const tipo = +queryParams['tipo'];
      if (tipo) {
        this.selectedTipo = tipo;
        this.agendamentoForm.controls['tipo'].setValue(tipo);
      }
      this.urlReturn = queryParams['urlReturn'] || null;
    });

    this.guid = sessionStorage.getItem('id') || '';
  }

  getLoja() {
    this.spinner = true;
    this.baseService.getDispenserById(this.agendamento.distribuidorId).subscribe(
      (response) => {
        this.spinner = false;
        this.loja = response;
        this.patchAgendamentoForm();
      },
      (error) => {
        this.spinner = false;
        this.processFail(error)
      }
    );
  }

  private setAgendamentoForm() {
    this.agendamentoForm = this.fb.group({
      id: [null],
      clienteIdGuid: ["", Validators.required],
      distribuidorId: ["", Validators.required],
      tipo: ["", Validators.required],
      formaContato: ["", Validators.required],
      formaContatoOutro: ["", Validators.required],
      telefone: ["", Validators.required],
      dataAgendamento: ["", Validators.required],
    })
    this.agendamentoForm.get('formaContatoOutro').disable();
    this.agendamentoForm.get('formaContato').valueChanges.subscribe((value) => {
      this.onFormaContatoChange(value);
    });
  }

  onFormaContatoChange(value: number): void {
    const formaContatoOutroControl = this.agendamentoForm.get('formaContatoOutro');

    if (value == 3) {
      formaContatoOutroControl.enable();
      formaContatoOutroControl.setValidators(Validators.required);
    } else {
      formaContatoOutroControl.disable();
      formaContatoOutroControl.clearValidators();
      formaContatoOutroControl.setValue('');
    }
    formaContatoOutroControl.updateValueAndValidity();
  }

  private patchAgendamentoForm() {
    if (this.agendamento) {
      this.agendamentoForm.patchValue({
        id: this.agendamento.id,
        clienteIdGuid: this.agendamento.clienteIdGuid,
        distribuidorId: this.agendamento.distribuidorId,
        tipo: this.selectedTipo || this.agendamento.tipo || null,
        formaContato: this.agendamento.formaContato || null,
        formaContatoOutro: this.agendamento.formaContato == 3 && this.agendamento.formaContatoOutro ? this.agendamento.formaContatoOutro : null,
        telefone: this.agendamento.telefone || null,
        dataAgendamento: this.formatToDateTimeLocal(this.agendamento.dataAgendamento) || null,
      });
      this.agendamentoForm.updateValueAndValidity();
    }
  }

  private formatToDateTimeLocal(dateTime: string | null): string | null {
    if (!dateTime) return null;

    // Parse the date, ensuring we handle both ISO strings and other date formats
    const date = new Date(dateTime);

    // Check for invalid date (like 1969/12/31)
    if (date.getFullYear() <= 1970) return null;

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  private removeNullValues(obj: any): any {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v)
    );
  }

  onSubmit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let formData = this.agendamentoForm.getRawValue();
    formData = this.removeNullValues(formData);

    if (this.agendamentoForm.valid) {
        this.spinner = true;

        this.baseService.postAgendamento(formData).subscribe({
            next: (response) => {
                this.spinner = false;
                this.processApiSuccess('Agendamento Realizado!');

                setTimeout(() => {
                    if (this.urlReturn) {
                        this.router.navigate([this.urlReturn]);
                    } else {
                        this.router.navigate(['/pre-cadastro/final'], {
                            queryParams: { id: this.guid }
                        });
                    }
                }, 2000);
            },
            error: (errorResponse) => {
                this.spinner = false;
                this.processFail(errorResponse);
            },
        });
    } else {
        this.agendamentoForm.markAllAsTouched();
    }
}

  processFail(errorResponse: any) {
    if (
      errorResponse.error instanceof ProgressEvent &&
      errorResponse.error.errors &&
      Array.isArray(errorResponse.error.errors)
    ) {
      errorResponse.error.errors.forEach((message: string) =>
        this.toastr.error(message)
      );
    } else if (errorResponse.error && errorResponse.error.errors) {
      const errors = errorResponse.error.errors;
      Object.keys(errors).forEach((key) => {
        const errorMessages = errors[key];
        if (Array.isArray(errorMessages)) {
          errorMessages.forEach((message) => {
            this.toastr.error(message);
          });
        }
      });
    }
  }

  processApiSuccess(message: string) {
    this.toastr.success(message, 'Sucesso!');
  }
}
