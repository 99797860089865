import { Component } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cadastro-leads';
  showHeaderFooter = true;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showHeaderFooter =
        event.urlAfterRedirects !== '/' &&
        !event.urlAfterRedirects.startsWith('/seja-uma-consultora') &&
        !event.urlAfterRedirects.startsWith('/seja-uma-consultora-de-sucesso') &&
        !event.urlAfterRedirects.startsWith('/?');
      setTimeout(() => {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }, 500);
    });
  }
}
