import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BaseService } from '../../services/base.service';
import { register } from 'swiper/element/bundle';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  cpfValidator,
  dataValid,
  rangeLengthValidator,
  validNameValidator,
  validPass,
  cepValidator,
  emailValidator,
} from '../../utils/generic-validator';
import { ValidationMessages } from '../../pre-cadastro/validation-messages';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../services/local-storage.service';

register();
@Component({
  selector: 'app-seja-uma-consultora',
  templateUrl: './seja-uma-consultora.component.html',
  styleUrls: ['./seja-uma-consultora.component.scss'],
})
export class SejaUmaConsultoraComponent implements OnInit {
  expandedState = [false, false, false, false];
  step1Form!: FormGroup;
  validationMessages = ValidationMessages;
  hideDataNascimento: boolean = false;
  spinner = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private fb: FormBuilder,
    private baseService: BaseService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private localStorageService:LocalStorageService<any>,
  ) {
    this.titleService.setTitle('Seja um consultor de sucesso');
  }

  utmsParams: string | undefined;

  ngOnInit(): void {
    this.initForm();
    this.PostStep();
    const swiperEl1 = document.getElementById('swiper-container1');
    const swiperEl2 = document.getElementById('swiper-container2');
    const swiperParams = {
      slidesPerView: 1,
      loop: true,
      navigation: true,
      pagination: true,
      lazyLoad: true,
    };
    Object.assign(swiperEl1, swiperParams);
    Object.assign(swiperEl2, swiperParams);

    // Set focus on the nome input after a short delay
    setTimeout(() => {
      const nomeInput = document.getElementById('nome');
      if (nomeInput) {
        nomeInput.focus();
      }
    }, 100);
  }

  PostStep() {
    let step = this.baseService.ObterStepLocalStorage('/seja-uma-consultora-entrada');
    this.baseService.postStepConsultant(step).subscribe();
    this.localStorageService.setItem('cadastro-realizado', false);
  }

  PostStepSubmit(cpf: string, email: string){
    let step = this.baseService.ObterStepLocalStorage("/seja-uma-consultora-saida")
    step.cpf = cpf;
    step.email = email;
    this.localStorageService.setItem('cadastro-realizado', true);
    this.baseService.postStepConsultant(step).subscribe();
    this.localStorageService.setItem('step', step);
  }

  toggleExpand(index: number, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.expandedState[index] = !this.expandedState[index];
  }

  getCardClass(index: number): string {
    return this.expandedState[index] ? 'expanded' : '';
  }

  initForm() {
    this.step1Form = this.fb.group({
      nome: [
        '',
        [
          Validators.required,
          rangeLengthValidator(2, 50),
          validNameValidator(),
        ],
      ],
      cpf: ['', [Validators.required, cpfValidator()]],
      dataNascimento: ['', [Validators.required, dataValid()]],
      senha: ['', [Validators.required, rangeLengthValidator(6, 15), validPass()]],
      email: [
        '',
        [
          Validators.required,
          rangeLengthValidator(1, 100),
          emailValidator(),
        ],
      ],
      celular: ['', [Validators.required, rangeLengthValidator(11, 11)]],
      cep: ['', [Validators.required, cepValidator()]],
      aceiteTermos: [false, Validators.requiredTrue],
      aceiteOferta: [false],
      utMs: [null],
    });

    this.captureUtms();
  }

  captureUtms() {
    if (
      this.route.snapshot.queryParams['utm_source'] ||
      this.route.snapshot.queryParams['utm_medium'] ||
      this.route.snapshot.queryParams['utm_campaign']
    ) {
      this.step1Form.patchValue({
        utMs: {
          utmSource: this.route.snapshot.queryParams['utm_source']??"",
          utmMedium: this.route.snapshot.queryParams['utm_medium']??"",
          utmCampaign: this.route.snapshot.queryParams['utm_campaign']??"",
        },
      });
    }
  }

  onSubmit() {
    const aceiteTermosFormControl = this.step1Form.get('aceiteTermos');
    let canSubmit = true;

    if (aceiteTermosFormControl.errors) {
      this.toastr.error(
        'Você deve aceitar os termos de uso para prosseguir.',
        'Erro'
      );
      canSubmit = false;
    }

    if (this.step1Form.valid) {
      if (!canSubmit) return;
      this.spinner = true;
      const dadosIniciais = this.step1Form.getRawValue();
      dadosIniciais["dataNascimento"] = this.formatDateToISO(dadosIniciais["dataNascimento"]);
      dadosIniciais["idguid"] = this.localStorageService.getItem("step").id;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.baseService.postDadosIniciais(dadosIniciais).subscribe({
        next: (response) => {
            this.spinner = false;
            const id = response;
            sessionStorage.setItem('nome', dadosIniciais.nome)
            sessionStorage.setItem('cep', dadosIniciais.cep)
            sessionStorage.setItem('cpf', dadosIniciais.cpf)
            sessionStorage.setItem('email', dadosIniciais.email)
            this.processApiSuccess('Complemente seu cadastro.');
            this.PostStepSubmit(dadosIniciais.cpf, dadosIniciais.email)
            setTimeout(() => {
              this.router.navigate(['/pre-cadastro/endereco'], {
                queryParams: { id: id },
              });
            }, 2000);
        },
        error: (errorResponse) => {
          this.spinner = false;
          this.processApiValidationError(errorResponse);
        },
      });
    } else {
      this.step1Form.markAllAsTouched();
    }
  }

  processApiValidationError(errorResponse: any) {
    if (errorResponse && errorResponse.error && errorResponse.error.errors) {
      const topLevelErrors = errorResponse.error.errors;

      if (topLevelErrors.cliente && Array.isArray(topLevelErrors.cliente)) {
        topLevelErrors.cliente.forEach((message: string) => {
          if (
            message === 'Cliente já cadastrada com este email' ||
            message === 'Cliente já cadastrada com este cpf'
          ) {
            this.redirectToExistingUser();
          } else {
            this.toastr.error(message);
          }
        });
      } else {
        if (topLevelErrors.errors && topLevelErrors.errors.DomainNotification) {
          const domainErrors = topLevelErrors.errors.DomainNotification;

          if (Array.isArray(domainErrors)) {
            domainErrors.forEach((message: string) => {
              this.toastr.error(message);
            });
          } else if (typeof domainErrors === 'object') {
            Object.keys(domainErrors).forEach((key) => {
              const errorMessages = domainErrors[key];
              if (Array.isArray(errorMessages)) {
                errorMessages.forEach((message) => {
                  this.toastr.error(message);
                });
              }
            });
          }
        } else if (typeof topLevelErrors === 'object') {
          Object.keys(topLevelErrors).forEach((key) => {
            const errorMessages = topLevelErrors[key];
            if (Array.isArray(errorMessages)) {
              errorMessages.forEach((message) => {
                this.toastr.error(message);
              });
            }
          });
        } else if (typeof topLevelErrors === 'string') {
          this.toastr.error(topLevelErrors);
        } else {
          this.toastr.error('Erro desconhecido ao processar a validação');
        }
      }
    } else {
      this.toastr.error('Erro desconhecido ao processar a validação.');
    }
  }

  processApiSuccess(message: string) {
    this.toastr.success(message, 'Sucesso!');
  }

  displayErrorMessage(formControlName: string): string | null {
    const formControl = this.step1Form.get(formControlName);

    if (formControl && formControl.touched && formControl.errors) {
      const errors = formControl.errors;

      for (const errorType of Object.keys(errors)) {
        if (this.validationMessages[formControlName]?.[errorType]) {
          return this.validationMessages[formControlName][errorType];
        }
      }
    }

    return null;
  }

  openModal(content: any): void {
    this.modalService.open(content, { size: 'xl' });
  }

  triggerHidden() {
    this.hideDataNascimento = true;
  }

  showHidePass(idInput: string, idIcon: string): void {
    let input = <HTMLInputElement>document.getElementById(idInput);
    let icon = <HTMLInputElement>document.getElementById(idIcon);

    input.type === 'password'
      ? (input.type = 'text')
      : (input.type = 'password');

    icon.className === 'fa fa-eye-slash'
      ? (icon.className = 'fa fa-eye')
      : (icon.className = 'fa fa-eye-slash');
  }

  formatDateToISO(dateString: string): string {
    const regexDate = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(19|20)\d{2}$/;

    if (regexDate.test(dateString)) {
      const day = dateString.slice(0, 2);
      const month = dateString.slice(2, 4);
      const year = dateString.slice(4, 8);
      const formattedDate = `${year}-${month}-${day}`;

      const parsedDate = new Date(formattedDate);

      if (!isNaN(parsedDate.getTime())) {
        return parsedDate.toISOString().split('T')[0];
      } else {
        throw new RangeError("Data inválida ao tentar converter para ISO.");
      }
    } else {
      throw new RangeError("Formato de data inválido. Esperado ddMMyyyy.");
    }
  }

  private redirectToExistingUser() {
    const formData = this.step1Form.getRawValue();
    sessionStorage.setItem('cpf', formData.cpf);
    sessionStorage.setItem('email', formData.email);

    this.router.navigate(['/cadastro-existente']);
  }

  scrollToTop(event: Event) {
    event.preventDefault(); // Prevent the default anchor behavior
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
