import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingPageAppComponent } from './landing-page.app.component';
import { SejaUmaConsultoraComponent } from './seja-uma-consultora/seja-uma-consultora.component';

const landingPagesRouteConfig: Routes = [
    {
      path: 'seja-uma-consultora',
      component: LandingPageAppComponent,
      children: [{ path: '', component: SejaUmaConsultoraComponent }],
    },
    {
      path: 'seja-uma-consultora-de-sucesso',
      component: LandingPageAppComponent,
      children: [{ path: '', component: SejaUmaConsultoraComponent }],
    },
    {
      path: '',
      component: LandingPageAppComponent,
      children: [{ path: '', component: SejaUmaConsultoraComponent }],
    },
];

@NgModule({
  imports: [RouterModule.forChild(landingPagesRouteConfig)],
  exports: [RouterModule],
})
export class LadingPagesRoutingModule { }
