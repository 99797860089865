import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(formGroup: FormGroup): ValidationErrors | null {
  const senhaControl = formGroup.get('senha');
  const confirmaSenhaControl = formGroup.get('confirmaSenha');

  if (!senhaControl || !confirmaSenhaControl) {
    return null;
  }

  const senha = senhaControl.value;
  const confirmaSenha = confirmaSenhaControl.value;

  if (senha !== confirmaSenha) {
    confirmaSenhaControl.setErrors({ passesDontMatch: true });
  } else {
    confirmaSenhaControl.setErrors(null);
  }

  return null;
}

export function rangeLengthValidator(
  minLength: number,
  maxLength: number
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value && (value.length < minLength || value.length > maxLength)) {
      return { rangeLength: true };
    }
    return null;
  };
}

export function validNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const valid = /^[a-zA-ZÀ-ÿ\s]+$/.test(value);
    return !valid ? { validName: true } : null;
  };
}

export function validNicknameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const valid = /^[a-zA-ZÀ-ÿ]+$/.test(value);
    return !valid ? { validNickname: true } : null;
  };
}


export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cpf = control.value;

    if (!cpf) {
      return null; // If no value, no validation error (assuming field is not required)
    }

    // Remove any non-numeric characters
    const cleanedCPF = cpf.replace(/[^\d]/g, '');

    // Validate length and format
    if (cleanedCPF.length !== 11 || !/^\d{11}$/.test(cleanedCPF)) {
      return { cpf: true }; // Invalid CPF format
    }

    // Validate CPF algorithm
    if (!isValidCPF(cleanedCPF)) {
      return { cpf: true }; // Invalid CPF according to validation rules
    }

    return null; // Valid CPF
  };
}

export function isValidCPF(cpf: string): boolean {
  let sum = 0;
  let remainder: number;

  // CPFs with all digits the same are invalid
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
}

export function dataValid(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const regexDate = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    let birthDate = control.value;

    if (birthDate.length === 8) {
      const day = birthDate.slice(0, 2);
      const month = birthDate.slice(2, 4);
      const year = birthDate.slice(4, 8);
      birthDate = `${year}-${month}-${day}`;
    }

    const birthDateRegex = birthDate.match(regexDate);

    if (!birthDateRegex) {
      return { invalidDate: true };
    }

    const [year, month, day] = birthDate.split('-');
    const parsedDate = new Date(`${year}-${month}-${day}`);

    if (isNaN(parsedDate.getTime())) {
      return { invalidDate: true };
    }

    const getAge = (birthDay: Date) =>
      Math.floor((Date.now() - birthDay.getTime()) / 3.15576e10);

    if (getAge(parsedDate) >= 100) {
      return { oldPerson: true };
    }

    if (getAge(parsedDate) < 0) {
      return { invalidDate: true };
    }

    if (getAge(parsedDate) < 18) {
      return { validAdult: true };
    }

    return null;
  };
}

export function cnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cnpj = control.value;

    if (!cnpj) {
      return null;
    }

    if (!isValidCNPJ(cnpj)) {
      return { invalidCNPJ: true };
    }
    return null;
  };
}

function isValidCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  // CNPJs with all digits the same are invalid
  if (/^(\d)\1+$/.test(cnpj)) return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  const digits = cnpj.substring(length);
  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += parseInt(numbers.charAt(length - i), 10) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) return false;

  length = length + 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;
  for (let i = length; i >= 1; i--) {
    sum += parseInt(numbers.charAt(length - i), 10) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) return false;

  return true;
}


export function validPass(): ValidatorFn {
  return (password: AbstractControl): ValidationErrors | null => {
    const regexValid = `^(?=(?:.*[0-9]){${1},})(?=(?:.*[a-z]){${1},})(?=(?:.*[A-Z]){${1},}).+$`;
    const passwordRegEx = new RegExp(regexValid, 'm');

    if (password.value.indexOf(' ') >= 0) {
      return { space: true };
    }

    if (!passwordRegEx.test(password.value)) {
      return { typeLetter: true };
    }

    return null;
  };
}
