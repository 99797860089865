import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  windowScroll: number = 0;

  constructor() {
    document.addEventListener('scroll', () => {
      this.windowScroll = window.scrollY;
    })
  }

}
