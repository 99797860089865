import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SejaUmaConsultoraComponent } from './seja-uma-consultora/seja-uma-consultora.component';
import { LadingPagesRoutingModule } from './landing-page.route';
import { RouterModule } from '@angular/router';
import { LandingPageAppComponent } from './landing-page.app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { UtilsModule } from '../utils/utils.module';



@NgModule({
  declarations: [
    LandingPageAppComponent,
    SejaUmaConsultoraComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LadingPagesRoutingModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    UtilsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingPageModule { }
