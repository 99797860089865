import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: 'pre-cadastro',
    loadChildren: () =>
      import('./pre-cadastro/pre-cadastro.module').then(
        (m) => m.PreCadastroModule
      ),
  },
  {
    path: 'cadastro-existente',
    loadComponent: () =>
      import('./cadastro-existente/cadastro-existente.component').then(
        (c) => c.CadastroExistenteComponent
      ),
  },
  {
    path: 'agendamento/:id',
    loadComponent: () =>
      import('./agendamento/agendamento.component').then(
        (c) => c.AgendamentoComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
