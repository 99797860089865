interface Environment {
  production: boolean;
  apiUrl: {
    consultant: {
      v1: {
        consultant: string;
        dispenser: string;
      };
      v2: {
        consultant: string;
        dispenser: string;
      };
      v3: {
        dadosComplementares: string;
        consultant: string;
        cliente: string;
        leads: string;
        dispenser: string;
      };
    };
    address: {
      v1: {
        cep: string;
      };
    };
  };
  URLs: {
    loginRecover: string;
    ecommerce: string;
  };
  keyMaps: string;
  keyApplicationInsights: string;
  imagesLocal: string;
}

const baseUrl = {
  urlConsultant: 'https://consultorah.cdnetweb.com.br/api/',
  urlAddress: 'https://endereco.cdnetweb.com.br/api/',
};

export const environment: Environment = {
  production: true,

  apiUrl: {
    consultant: {
      v1: {
        consultant: `${baseUrl.urlConsultant}v1/ConsultoraRommanel/`,
        dispenser: `${baseUrl.urlConsultant}v1/Distribuidor/`,
      },

      v2: {
        consultant: `${baseUrl.urlConsultant}v2/ConsultoraRommanel/`,
        dispenser: `${baseUrl.urlConsultant}v2/Distribuidor/`,
      },

      v3: {
        dispenser: `${baseUrl.urlConsultant}v3/Distribuidor/`,
        consultant: `${baseUrl.urlConsultant}v3/Cliente/rommanel/`,
        cliente: `${baseUrl.urlConsultant}v3/Cliente/`,
        leads: `${baseUrl.urlConsultant}v3/Cliente/leads/`,
        dadosComplementares: `${baseUrl.urlConsultant}v3/Cliente/rommanel/DadosComplementares`
      },
    },

    address: {
      v1: {
        cep: `${baseUrl.urlAddress}v1/Endereco/`,
      },
    },
  },

  URLs: {
    loginRecover: 'https://loginh.cdnetweb.com.br/Account/ForgotPassword',
    ecommerce: 'https://rommaneldevqab2b.myvtex.com',
  },

  keyMaps: 'AIzaSyA38mWxrqafgRwFwouxrWmdiCmGsti0C4g',
  keyApplicationInsights: '44f67243-2c3a-4bec-830e-a0f9e6e68d57',
  imagesLocal: '../assets/img/',
};
