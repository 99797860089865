<footer>
  <div class="rommanel-url">
    <a href="https://rommanel.com.br/" target="_blank">rommanel.com.br</a>
    <p>|</p>
  </div>
  <div class="social-media-icons">
    <a href="https://www.facebook.com/RommanelOficial/about?locale=pt_BR" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_facebook.png">
    </a>
    <a href="https://www.instagram.com/rommanel/" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_instagram.png">
    </a>
    <a href="https://br.linkedin.com/company/rommanel" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_linkedIn.png">
    </a>
    <a href="https://www.threads.net/@rommanel?xmt=AQGzxgEqS0VY0F-ZkOVCav9huzxFpeN0Tkd1wio5mM3QpM8" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_threads.png">
    </a>
    <a href="https://www.tiktok.com/@rommaneloficial" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_tiktok.png">
    </a>
    <a href="https://x.com/rommanel" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_twitter.png">
    </a>
    <a href="https://www.youtube.com/@rommanel" target="_blank">
      <img class="social-media-icon"
        src="https://storage.googleapis.com/api-imagens/imagens/cadastroleads/icon_youtube.png">
    </a>
  </div>
</footer>